import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { Logger } from '../../../../Errors/Logger';
import { ConfigurationUtils } from '../../../../Helpers/ConfigurationUtils';
import { ProductHelper } from '../../../../Helpers/ProductHelper';
import { QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { GetConfigurationResponse } from '../../../../Models/Projects/Configuration';
import { QuestionContextType, QuestionItem, QuestionStep } from '../../../../Models/Questions/QuestionDto';
import { CompleteHelper } from '../../../../Plugins/FloorPlan/Helpers/CompleteHelper';
import { MapExportHelper } from '../../../../Plugins/FloorPlan/Helpers/MapExport/MapExportHelper';
import { MapHelper } from '../../../../Plugins/FloorPlan/Helpers/MapHelper';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom, RoomType } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { ConfigurationApi } from '../../../../Services/Api/ConfigurationApi';
import { useIsVendeur } from '../../../Hooks/Vendeur/useVendeur';
import { useRoomInfosValues } from '../Components/Pannels/Rooms/Controllers/RoomInfosValues';
import { HabitationHelper } from '../Helpers/HabitationHelper';
import { getQuestionProduitPrincipal, getQuestionProduitPrincipalLogData } from '../Helpers/ProduitPrincipalHelper';
import { PrepareStepQuestionsListParams, QuestionPrepare } from '../Helpers/QuestionPrepare';
import { LoadingStep, QuoteState } from '../QuoteState';
import { QuestionContextUtils } from '../Utils/QuestionContextUtils';
import { getStepServiceBaseContext, getStepSyntheseBaseContext } from '../Utils/UpdateQuestions';

type LoadConfigurationParams = { configId: string; questions: Array<QuestionItem> };

type QuoteLoadControllerParams = { updateQuote: (values: Partial<QuoteState>) => void };
export const useQuoteLoadController = ({ updateQuote }: QuoteLoadControllerParams) => {
    const isVendeur = useIsVendeur();
    const currentProject = useCurrentProject();
    const roomInfos = useRoomInfosValues();

    const loadConfiguration = async ({ configId, questions }: LoadConfigurationParams) => {
        updateQuote({ loadingStep: LoadingStep.Configuration });
        const configurationResponse: GetConfigurationResponse = await ConfigurationApi.getConfiguration(configId);
        const {
            floor_plan_json,
            reponse_list: reponses,
            configuration_projet,
            projet_store,
        } = configurationResponse.data;
        currentProject.update({
            project: configuration_projet.projet,
            isUpdateAllowed: configuration_projet.is_update_allowed,
            isTouchedBySellers: configuration_projet.touched_by_sellers,
            isDirty: false,
            lastSaveDate: undefined,
            lastAutoSave: false,
        });

        const stepHabitation = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepHabitation);
        const stepServices = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepServices);
        const stepSynthese = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepSynthese);

        const params: PrepareStepQuestionsListParams = {
            reponses,
            autoValidQuestionCommentType: true,
            fromLoadingConfiguration: true,
        }
        QuestionPrepare.updatedStepQuestionsFromLoadConfig({ ...params, questions: stepHabitation.questions });

        const { state: exportMap, flooringDirectionByRoomId } = MapExportHelper.fromApiJsonToState(floor_plan_json, roomInfos);

        exportMap.rooms?.forEach((item: IRoom) => {
            //* load question product principal response
            const questionProduitPrincipal = getQuestionProduitPrincipal(item, questions, stepHabitation.questions)!;
            if (!questionProduitPrincipal) {
                Logger.logAnalytics({
                    data: {
                        configId,
                        ...getQuestionProduitPrincipalLogData(item, questions, stepHabitation.questions),
                    },
                    value: { error: new Error('questionProduitPrincipal is missing') },
                });
            } else {
                item.questionProduitPrincipal = ConfigurationUtils.applyReponseToQuestion({
                    contextId: item.roomId,
                    question: questionProduitPrincipal,
                    reponses,
                });
            }
        });

        const laizeProps = ProductHelper.ToArrayLaizeProps(exportMap.rooms);
        updateQuote({ loadingStep: LoadingStep.Map });
        const map = MapHelper.loadRooms({ rooms: exportMap.rooms!, flooringDirectionByRoomId, laizeProps });

        updateQuote({ loadingStep: LoadingStep.InitConfiguration });
        map.rooms?.forEach((item: IRoom) => {
            const roomQuestionProduitPrincipal = item.questionProduitPrincipal!;
            const produitPrincipal = roomQuestionProduitPrincipal.produitValue;
            const flooringDirection = flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextHelper.mergeContext(
                QuestionContextHelper.getBaseQuestionsContext(stepHabitation.questions),
                QuestionContextUtils.createRoomContext(item, flooringDirection)
            );

            QuestionPrepare.updateItemStepsQuestions({
                itemId: item.roomId!,
                item,
                questions,
                produitPrincipal,
                contextType: item.type === RoomType.Sol ? QuestionContextType.Rooms : QuestionContextType.Walls,
                fromLoadingConfiguration: true,
                reponses,
                isVendeur,
            });

            item.openings?.forEach((opening: IOpening) => {
                opening.questionsBaseContext = QuestionContextUtils.createOpeningContext(
                    opening,
                    item,
                    flooringDirection
                );
                QuestionPrepare.updateItemStepsQuestions({
                    itemId: opening.openingId!,
                    item: opening,
                    questions,
                    produitPrincipal,
                    contextType: QuestionContextType.Openings,
                    openingType: opening.type,
                    fromLoadingConfiguration: true,
                    reponses,
                    isVendeur,
                });
                opening.completionStatus = CompleteHelper.isOpeningComplete(opening, item.openings!);
            });
            item.roomItems?.forEach((roomItem: IRoomItem) => {
                roomItem.questionsBaseContext = QuestionContextUtils.createRoomItemContext(
                    roomItem,
                    item,
                    flooringDirection
                );
                QuestionPrepare.updateItemStepsQuestions({
                    itemId: roomItem.roomItemId!,
                    item: roomItem,
                    questions,
                    produitPrincipal,
                    contextType: QuestionContextType.RoomElements,
                    roomItemType: roomItem.type,
                    fromLoadingConfiguration: true,
                    reponses,
                    isVendeur,
                });
                roomItem.completionStatus = CompleteHelper.isRoomItemComplete(roomItem, item.roomItems!);
            });
            const findFirstIncompatibility = QuestionsHelper.findFirstIncompatibility([
                ...item.questionsTva?.questions || [],
                item.questionProduitPrincipal!,
                ...item.questionsPose?.questions || [],
                ...item.questionsSupport?.questions || [],
                ...item.questionsPreparation?.questions || [],
                ...item.questionsFinition?.questions || [],
            ]);
            item.findIncompatibilityResult = findFirstIncompatibility.findIncompatibility
                ? findFirstIncompatibility
                : undefined;
            item.completionStatus = CompleteHelper.isRoomComplete(item, map.rooms!);
        });

        const habitationQuestionsOpen = !HabitationHelper.isFinished(stepHabitation.questions);
        map.roomsCompleted = CompleteHelper.allComplete(map.rooms);

        const quote: QuoteState = { stepHabitation, stepServices, stepSynthese, habitationQuestionsOpen, projetStore: projet_store, };

        const optionsInitialContext = getStepServiceBaseContext(map, quote);
        QuestionPrepare.updatedStepQuestionsFromLoadConfig({ ...params, questions: stepServices.questions, initialContext: optionsInitialContext });

        const syntheseInitialContext = getStepSyntheseBaseContext(map, quote);
        QuestionPrepare.updatedStepQuestionsFromLoadConfig({ ...params, questions: stepSynthese.questions, initialContext: syntheseInitialContext });

        return { mapState: map, ...quote };
    };

    return { loadConfiguration };
};
