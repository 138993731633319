import { YesOrNo } from '../../../../Models/IMapItem';
import { OpeningType } from '../../../../Models/IOpening';
import { IRoom, RoomShape } from '../../../../Models/IRoom';
import { RoomItemType } from '../../../../Models/IRoomItem';

export interface TestCase {
    name?: string;
    rooms?: Array<IRoom>;
    savedPlan?: TestCase;
}
export const LAIZE_TEST_CASES: Array<TestCase> = [
    {
        name: 'Rectangle 5x3',
        rooms: [
            {
                name: 'Rectangle5x3',
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 5, y: 0 },
                    { x: 5, y: 3 },
                    { x: 0, y: 3 },
                ],
            },
        ],
    },
    {
        name: 'Rectangle 5x3 (rota 90)',
        rooms: [
            {
                name: 'Rectangle5x3',
                rotationAngle: 90,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 5, y: 0 },
                    { x: 5, y: 3 },
                    { x: 0, y: 3 },
                ],
            },
        ],
    },
    {
        name: 'Rectangle 4x3 + U',
        rooms: [
            {
                name: 'Rectangle 4x3',
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 4, y: 0 },
                    { x: 4, y: 3 },
                    { x: 0, y: 3 },
                ],
            },
            {
                name: 'Pièce en U vers le haut',
                rotationAngle: 180,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: 8 },
                    { x: 4, y: 8 },
                    { x: 4, y: 2 },
                    { x: 8, y: 2 },
                    { x: 8, y: 8 },
                    { x: 12, y: 8 },
                    { x: 12, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Diamant top left',
        rooms: [
            {
                name: 'Diamant top left',
                rotationAngle: 45,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: -2 },
                    { x: 2, y: -4 },
                    { x: 4, y: -4 },
                    { x: 4, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Rectangles V&H',
        rooms: [
            {
                name: 'Rectangle Vertical',
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: -8 },
                    { x: 5, y: -8 },
                    { x: 5, y: 0 },
                ],
            },
            {
                name: 'Rectangle Horizontal',
                rotationAngle: 90,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: -3 },
                    { x: 5, y: -3 },
                    { x: 5, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Pièce en U vers le bas',
        rooms: [
            {
                name: 'Pièce en U vers le bas',
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: 8 },
                    { x: 4, y: 8 },
                    { x: 4, y: 2 },
                    { x: 8, y: 2 },
                    { x: 8, y: 8 },
                    { x: 12, y: 8 },
                    { x: 12, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Pièce en U vers le haut',
        rooms: [
            {
                name: 'Pièce en U vers le haut',
                rotationAngle: 180,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: 8 },
                    { x: 4, y: 8 },
                    { x: 4, y: 2 },
                    { x: 8, y: 2 },
                    { x: 8, y: 8 },
                    { x: 12, y: 8 },
                    { x: 12, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Pièce en U vers la droite',
        rooms: [
            {
                name: 'Pièce en U vers la droite',
                rotationAngle: -90,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 0, y: 8 },
                    { x: 4, y: 8 },
                    { x: 4, y: 2 },
                    { x: 8, y: 2 },
                    { x: 8, y: 8 },
                    { x: 12, y: 8 },
                    { x: 12, y: 0 },
                ],
            },
        ],
    },
    {
        name: 'Pièce en étoile',
        rooms: [
            {
                name: 'Pièce en étoile 1',
                customPoints: [
                    { x: 2, y: 0 },
                    { x: 2, y: 2 },
                    { x: 0, y: 4 },
                    { x: 2, y: 4 },
                    { x: 4, y: 8 },
                    { x: 6, y: 4 },
                    { x: 8, y: 4 },
                    { x: 6, y: 2 },
                    { x: 6, y: 0 },
                    { x: 4, y: 2 },
                ],
            },
        ],
    },
    {
        name: 'Rectangle 5x3 (rota 40°)',
        rooms: [
            {
                name: 'Rectangle5x3',
                rotationAngle: 40,
                customPoints: [
                    { x: 0, y: 0 },
                    { x: 5, y: 0 },
                    { x: 5, y: 3 },
                    { x: 0, y: 3 },
                ],
            },
        ],
    },
    {
        name: 'Diamant + Placard',
        savedPlan: {
            rooms: [
                {
                    roomId: '19526696',
                    name: 'Diamant + Placard',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 43.8, y: 82 },
                    roomItems: [
                        {
                            roomItemId: '95592572',
                            type: RoomItemType.Cupboard,
                            name: 'Placard',
                            putFlooring: YesOrNo.Yes,
                            putPlinthes: YesOrNo.No,
                            isMoveableOutside: true,
                            width: 100,
                            height: 100,
                            coords: [
                                { x: -50, y: -50 },
                                { x: 50, y: -50 },
                                { x: 50, y: 50 },
                                { x: -50, y: 50 },
                                { x: -50, y: -50 },
                            ],
                            graphTranslation: { x: 4470, y: 7950 },
                            coordsReal: [
                                { x: 4420, y: 7900 },
                                { x: 4520, y: 7900 },
                                { x: 4520, y: 8000 },
                                { x: 4420, y: 8000 },
                                { x: 4420, y: 7900 },
                            ],
                            // graph: {
                            //     0: {},
                            //     length: 1,
                            // },
                            roomId: '19526696',
                        },
                    ],
                    customPoints: [
                        { x: 41.8, y: 84 },
                        { x: 41.8, y: 82 },
                        { x: 43.8, y: 80 },
                        { x: 45.8, y: 80 },
                        { x: 45.8, y: 84 },
                    ],
                    rotationAngle: 45,
                },
            ],
        },
    },
    {
        name: 'Rectangle5x3 rota 90 + placard',
        savedPlan: {
            rooms: [
                {
                    roomId: '22638533',
                    name: 'Rectangle5x3',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: {
                        x: 44.3,
                        y: 81.5,
                    },
                    roomItems: [
                        {
                            roomItemId: '98582057',

                            type: RoomItemType.Cupboard,
                            name: 'Placard',
                            putFlooring: YesOrNo.Yes,
                            putPlinthes: YesOrNo.No,
                            isMoveableOutside: true,
                            width: 100,
                            height: 100,
                            coords: [
                                { x: -50, y: -50 },
                                { x: 50, y: -50 },
                                { x: 50, y: 50 },
                                { x: -50, y: 50 },
                                { x: -50, y: -50 },
                            ],
                            graphTranslation: {
                                wall: {
                                    start: { x: 4150, y: 7950 },
                                    end: { x: 4710, y: 7950 },
                                    roomId: '22638533',
                                    angle: 0,
                                },
                                roomId: '22638533',
                                x: 4274,
                                y: 7950,
                                distance: 5,
                            },
                            coordsReal: [
                                { x: 4224, y: 7900 },
                                { x: 4324, y: 7900 },
                                { x: 4324, y: 8000 },
                                { x: 4224, y: 8000 },
                                { x: 4224, y: 7900 },
                            ],
                            // graph: {
                            //     0: {},
                            //     length: 1,
                            // },
                            roomId: '22638533',
                        },
                    ],
                    customPoints: [
                        { x: 41.8, y: 80 },
                        { x: 46.8, y: 80 },
                        { x: 46.8, y: 83 },
                        { x: 41.8, y: 83 },
                    ],
                    rotationAngle: -90,
                },
            ],
        },
    },
    {
        name: '3 pièces différentes',
        savedPlan: {
            rooms: [
                {
                    roomId: '76561366',
                    name: 'Rectangle5x3',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: { x: 55.09, y: 80.36 },
                    roomItems: [],
                    customPoints: [
                        { x: 52.59, y: 78.86 },
                        { x: 57.59, y: 78.86 },
                        { x: 57.59, y: 81.86 },
                        { x: 52.59, y: 81.86 },
                    ],
                },
                {
                    roomId: '17668114',
                    name: 'Chambre / Dressing - RDC',
                    shape: RoomShape.Diamond_topright,
                    // dimensions: {
                    //     left_crown: 3,
                    //     table: 2.8284271247461903,
                    //     right_crown: 5,
                    // },
                    roomExistingFloorType: 'default',
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 46.55, y: 80.72999999999999 },
                    openings: [],
                    roomItems: [],
                },
                {
                    roomId: '70614353',
                    name: 'Chambre / Dressing - RDC',
                    shape: RoomShape.Flyingwing_bottomright,
                    // dimensions: {
                    //     right_wingtip: 2,
                    //     right_trailing_edge: 5,
                    //     left_trailing_edge: 3,
                    //     left_wingtip: 3.5,
                    // },
                    roomExistingFloorType: 'default',
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 52.31, y: 87.31666666666666 },
                    openings: [],
                    roomItems: [],
                },
            ],
        },
    },
    {
        name: 'Pièces contigues',
        savedPlan: {
            rooms: [
                {
                    roomId: '77206721',
                    name: 'Rectangle5x3',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: {
                        x: 44.3,
                        y: 81.5,
                    },
                    roomItems: [],
                    customPoints: [
                        {
                            x: 41.8,
                            y: 80,
                        },
                        {
                            x: 46.8,
                            y: 80,
                        },
                        {
                            x: 46.8,
                            y: 83,
                        },
                        {
                            x: 41.8,
                            y: 83,
                        },
                    ],
                },
                {
                    roomId: '21252999',
                    name: 'Chambre / Dressing - RDC',
                    shape: RoomShape.Diamond_topleft,
                    // dimensions: {
                    //     left_crown: 3,
                    //     table: 2.8284271247461903,
                    //     right_crown: 5,
                    // },
                    roomExistingFloorType: 'default',
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 50, y: 81.83000000000001 },
                    openings: [
                        {
                            openingId: '49044181',
                            location_side: 'left_crown',
                            location_position_from_edge: 2.31,
                            size: 0.63,
                            name: 'Porte',
                            type: OpeningType.Door,
                        },
                    ],
                    roomItems: [],
                },
            ],
        },
    },
    {
        name: 'Pièces contigues, sens pose différent',
        savedPlan: {
            rooms: [
                {
                    roomId: '69977750',
                    name: 'Rectangle Vertical',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: { x: 44.3, y: 84 },
                    openings: [],
                    roomItems: [],
                    customPoints: [
                        { x: 41.8, y: 88 },
                        { x: 41.8, y: 80 },
                        { x: 46.8, y: 80 },
                        { x: 46.8, y: 88 },
                    ],
                },
                {
                    roomId: '66149100',
                    name: 'Rectangle Horizontal',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: { x: 49.3, y: 83.7 },
                    openings: [
                        {
                            openingId: '94661669',
                            location_side: 'side_0',
                            location_position_from_edge: 1.72,
                            size: 0.63,
                            name: 'Porte',
                            type: OpeningType.Door,
                        },
                    ],
                    roomItems: [],
                    customPoints: [
                        { x: 46.8, y: 85.2 },
                        { x: 46.8, y: 82.2 },
                        { x: 51.8, y: 82.2 },
                        { x: 51.8, y: 85.2 },
                    ],
                    rotationAngle: 90,
                },
            ],
        },
    },
    {
        name: 'Autre élément séparateur (U)',
        savedPlan: {
            rooms: [
                {
                    roomId: '47741136',
                    name: 'Rectangle5x3',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: { x: 44.3, y: 81.5 },
                    openings: [],
                    roomItems: [
                        {
                            roomItemId: '71423169',
                            type: RoomItemType.FixedFurniture,
                            name: 'Meuble non déplaçable',
                            putFlooring: YesOrNo.No,
                            putPlinthes: YesOrNo.No,
                            isMoveableOutside: false,
                            width: 477,
                            height: 56,
                            coords: [
                                { x: -238.5, y: -28 },
                                { x: 238.5, y: -28 },
                                { x: 238.5, y: 28 },
                                { x: -238.5, y: 28 },
                                { x: -238.5, y: -28 },
                            ],
                            graphTranslation: {
                                wall: {
                                    start: { x: 4418.5, y: 8272 },
                                    end: { x: 4418.5, y: 8028 },
                                    roomId: '47741136',
                                    angle: -1.5707963267948966,
                                },
                                roomId: '47741136',
                                x: 4418.5,
                                y: 8132,
                                distance: 210.5,
                            },
                            coordsReal: [
                                { x: 4180, y: 8104 },
                                { x: 4657, y: 8104 },
                                { x: 4657, y: 8160 },
                                { x: 4180, y: 8160 },
                                { x: 4180, y: 8104 },
                            ],
                            // graph: {
                            //     0: {},
                            //     length: 1,
                            // },
                            roomId: '47741136',
                        },
                    ],
                    customPoints: [
                        { x: 41.8, y: 80 },
                        { x: 46.8, y: 80 },
                        { x: 46.8, y: 83 },
                        { x: 41.8, y: 83 },
                    ],
                },
            ],
        },
    },
    {
        name: 'Ilot central',
        savedPlan: {
            rooms: [
                {
                    roomId: '47741136',
                    name: 'Rectangle5x3',
                    shape: RoomShape.Custom,
                    // dimensions: {},
                    center: { x: 46.120000000000005, y: 81.5 },
                    openings: [],
                    roomItems: [
                        {
                            roomItemId: '71423169',
                            type: RoomItemType.FixedFurniture,
                            name: 'Meuble non déplaçable',
                            putFlooring: YesOrNo.No,
                            putPlinthes: YesOrNo.No,
                            isMoveableOutside: false,
                            width: 733,
                            height: 56,
                            coords: [
                                { x: -366.5, y: -28 },
                                { x: 366.5, y: -28 },
                                { x: 366.5, y: 28 },
                                { x: -366.5, y: 28 },
                                { x: -366.5, y: -28 },
                            ],
                            graphTranslation: { x: 4609.25, y: 8155 },
                            coordsReal: [
                                { x: 4242.75, y: 8127 },
                                { x: 4975.75, y: 8127 },
                                { x: 4975.75, y: 8183 },
                                { x: 4242.75, y: 8183 },
                                { x: 4242.75, y: 8127 },
                            ],
                            // graph: {
                            //     0: {},
                            //     length: 1,
                            // },
                            roomId: '47741136',
                        },
                    ],
                    customPoints: [
                        { x: 41.8, y: 80 },
                        { x: 50.44, y: 80 },
                        { x: 50.44, y: 83 },
                        { x: 41.8, y: 83 },
                    ],
                },
            ],
        },
    },
    {
        name: 'Chute réutilisable',
        savedPlan: {
            rooms: [
                {
                    roomId: '77767612',
                    name: 'Chute réutilisable',
                    shape: RoomShape.Rectangle,
                    // dimensions: { width: 0.99, height: 3 },
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 42.295, y: 81.5 },
                    openings: [],
                    roomItems: [],
                },
                {
                    roomId: '97969171',
                    name: 'Chambre / Dressing - RDC',
                    shape: RoomShape.Rectangle,
                    // dimensions: { width: 0.99, height: 3 },
                    roomExistingFlooring: 'Oui',
                    roomFloor: 'RDC',
                    roomHeating: 'Non',
                    roomHeatingType: "Je n'ai pas de chauffage au sol",
                    roomType: 'Chambre / Dressing',
                    center: { x: 42.295, y: 84.5 },
                    openings: [],
                    roomItems: [],
                },
            ],
        },
    },
];
