import { I18n } from '../../../Locales/I18nService';

import { IRoom, ObsoleteRoomDimensions, RoomAngle, RoomShape, RoomType } from '../Models/IRoom';

const BaseRoom: IRoom = {
    type: RoomType.Sol,
    name: '',
    roomType: '',
    roomFloor: '',
    roomExistingFlooring: '',
    roomExistingFloorType: '',
    roomHeating: '',
    roomHeatingType: '',
    openings: [],
    roomItems: [],
};

const toDiamondDimensions = (width: number, height: number): ObsoleteRoomDimensions => {
    const min = Math.min(width, height);
    const table = min / 1.5;
    const unkownNumber = Math.sqrt(Math.pow(table, 2) / 2);
    const left_crown = width - unkownNumber;
    const right_crown = height - unkownNumber;
    //console.log('toDiamondDimension', { width, height, min, table, unkownNumber, left_crown, right_crown })
    return { left_crown, table, right_crown };
};

const FLYING_WING_SIZE_RATIO = 0.5; //0.625;(ratio de base avec les anciens tailles en dur)

export class RoomFactory {
    //#region //* NAME GENERATOR

    public static defaultName = (roomCount: number = 0, type: RoomType = RoomType.Sol) =>
        (type === RoomType.Sol ? 'Pièce' : 'Mur') + ` ${roomCount + 1}`;

    public static isDefaultName = (roomName: string = '') => {
        const [name, num] = roomName.split(' ');
        return name === 'Pièce' && Boolean(num.length) && Number.isFinite(parseInt(num));
    };

    public static getDuplicateName(room: IRoom, rooms: Array<IRoom>) {
        const roomNumbers = rooms
            .filter((r) => r.name)
            .map((r) => {
                const num = r.name!.split(' ')[1];
                return parseInt(num);
            });
        if (roomNumbers.length) {
            const name = room.name!.split(' ')[0];
            const max = Math.max(...roomNumbers) + 1;
            return `${name} ${max}`;
        }
        return `${room.name} 2`;
    }

    public static toUniqueName = (rooms: Array<IRoom>, roomType: string = '', roomFloor: string = '') => {
        if (
            roomFloor === I18n.get('RoomItemDialog_RoomFloorList_Etage1a3') ||
            roomFloor === I18n.get('RoomItemDialog_RoomFloorList_4etPlus')
        ) {
            roomFloor = I18n.get('RoomItemDialog_RoomFloorList_Floor'); //floor exception, fr string
        }
        const toBaseRoomName = (type: string = '', floor: string = '') => `${type}`;
        let wantedRoomName = toBaseRoomName(roomType, roomFloor);
        if (!rooms.some((x) => x.name === wantedRoomName)) {
            return wantedRoomName;
        } else {
            let i = 2;
            wantedRoomName = `${roomType} ${i}`;
            // eslint-disable-next-line no-loop-func
            while (rooms.some((x) => x.name === wantedRoomName)) {
                wantedRoomName = `${roomType} ${i}`;
                i++;
            }
            return wantedRoomName;
        }
    };

    //#endregion

    public static createBaseRoom = (
        roomShape: RoomShape,
        width: number,
        height: number,
        type: RoomType = RoomType.Sol
    ): IRoom => {
        switch (roomShape) {
            case RoomShape.Rectangle:
                return this.createRectangle(width, height, type);
            case RoomShape.Open_bottom_rectangle:
                return this.createOpenBottomRectangle(width, height, type);
            case RoomShape.Diamond_topleft:
                return this.createDiamondTopLeft(width, height, type);
            case RoomShape.Diamond_topright:
                return this.createDiamondTopRight(width, height, type);
            case RoomShape.Diamond_bottomleft:
                return this.createDiamondBottomLeft(width, height);
            case RoomShape.Diamond_bottomright:
                return this.createDiamondBottomRight(width, height);

            case RoomShape.Flyingwing_topleft:
                return this.createFlyingwingTopLeft(width, height);
            case RoomShape.Flyingwing_topright:
                return this.createFlyingwingTopRight(width, height);
            case RoomShape.Flyingwing_bottomleft:
                return this.createFlyingwingBottomLeft(width, height);
            case RoomShape.Flyingwing_bottomright:
                return this.createFlyingwingBottomRight(width, height);

            case RoomShape.U_top:
                return this.createU(width, height, 0, RoomShape.U_top);
            case RoomShape.U_right:
                return this.createU(width, height, 90, RoomShape.U_right);
            case RoomShape.U_bottom:
                return this.createU(width, height, 180, RoomShape.U_bottom);
            case RoomShape.U_left:
                return this.createU(width, height, 270, RoomShape.U_left);

            case RoomShape.Plus:
                return this.createPlus(width, height);

            default:
                throw new Error(`the ${roomShape} is not supported`);
        }
    };
    //#region //* RECTANGE

    private static createRectangle = (width: number, height: number, type: RoomType): IRoom => {
        return {
            ...BaseRoom,
            type,
            shape: RoomShape.Rectangle,
            //dimensions: { width: 4, height: type === RoomType.Sol ? 3 : 2.5 },
            obsoleteDimensions: { width, height },
        };
    };

    private static createOpenBottomRectangle = (width: number, height: number, type: RoomType): IRoom => {
        return {
            ...BaseRoom,
            type,
            shape: RoomShape.Open_bottom_rectangle,
            //dimensions: { width: 5, height: 4 },
            obsoleteDimensions: { width, height },
        };
    };

    //#endregion

    //#region //* DIAMOND
    private static createDiamondTopLeft = (width: number, height: number, type: RoomType): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_topleft,
            // dimensions: {
            //     left_crown: type === RoomType.Sol ? 1.5 : 1,
            //     table: 2.12,
            //     right_crown: 2.5,
            // },
            obsoleteDimensions: toDiamondDimensions(height, width),
        };
    };
    private static createDiamondTopRight = (width: number, height: number, type: RoomType): IRoom => {
        return {
            ...BaseRoom,
            type,
            shape: RoomShape.Diamond_topright,
            // dimensions: {
            //     left_crown: 2.5,
            //     table: 2.12,
            //     right_crown: type === RoomType.Sol ? 1.5 : 1,
            // },
            obsoleteDimensions: toDiamondDimensions(width, height),
        };
    };
    private static createDiamondBottomLeft = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_bottomleft,
            //dimensions: { left_crown: 2.5, table: 2.12, right_crown: 1.5 },
            obsoleteDimensions: toDiamondDimensions(width, height),
        };
    };
    private static createDiamondBottomRight = (width: number, height: number): IRoom => {
        return {
            ...BaseRoom,
            shape: RoomShape.Diamond_bottomright,
            //dimensions: { left_crown: 1.5, table: 2.12, right_crown: 2.5 },
            obsoleteDimensions: toDiamondDimensions(height, width),
        };
    };

    //#endregion

    //#region  //* FLYING
    private static createFlyingwingTopLeft = (width: number, height: number): IRoom => {
        const right_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const left_wingtip = width - right_trailing_edge;
        const right_wingtip = height / 2;
        const left_trailing_edge = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_topleft,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 2.5, left_trailing_edge: 1.5, left_wingtip: 1.5 },
            obsoleteDimensions: dimensions,
        };
    };
    private static createFlyingwingTopRight = (width: number, height: number): IRoom => {
        const left_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const right_wingtip = width - left_trailing_edge;
        const right_trailing_edge = height / 2;
        const left_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_topright,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 1.5, left_trailing_edge: 2.5, left_wingtip: 1.5 },
            obsoleteDimensions: dimensions,
        };
    };
    private static createFlyingwingBottomLeft = (width: number, height: number): IRoom => {
        const left_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const right_wingtip = width - left_trailing_edge;
        const right_trailing_edge = height / 2;
        const left_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_bottomleft,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 1.5, left_trailing_edge: 2.5, left_wingtip: 1.5 },
            obsoleteDimensions: dimensions,
        };
    };
    private static createFlyingwingBottomRight = (width: number, height: number): IRoom => {
        const right_trailing_edge = width * FLYING_WING_SIZE_RATIO;
        const left_wingtip = width - right_trailing_edge;
        const left_trailing_edge = height / 2;
        const right_wingtip = height / 2;
        const dimensions = { right_wingtip, right_trailing_edge, left_trailing_edge, left_wingtip };
        return {
            ...BaseRoom,
            __comment_enum__: 'flyingwing_topleft, flyingwing_topright; flyingwing_bottomleft; flyingwing_bottomright',
            shape: RoomShape.Flyingwing_bottomright,
            //dimensions: { right_wingtip: 1.5, right_trailing_edge: 2.5, left_trailing_edge: 1.5, left_wingtip: 1.5 },
            obsoleteDimensions: dimensions,
        };
    };

    //#endregion

    //#region //* U
    private static createU = (width: number, height: number, angle: RoomAngle, shape: RoomShape): IRoom => {
        let exterior_base;
        let left_exterior_edge;
        let left_tip;
        let left_interior_edge;
        let right_tip;
        let right_exterior_edge;
        const tipConstant = 0.375;
        console.log({ width, height, angle });
        const roundedThird =
            angle === 0 || angle === 180
                ? Math.ceil(width * tipConstant * 100) / 100
                : Math.ceil(height * tipConstant * 100) / 100; //avoid aving infinite decimals
        switch (angle) {
            default:
            case 0:
            case 180:
                exterior_base = width;
                left_exterior_edge = height;
                left_tip = roundedThird;
                left_interior_edge = height / 2;
                right_tip = roundedThird;
                right_exterior_edge = height;
                break;
            case 90:
            case 270:
                exterior_base = height;
                left_exterior_edge = width;
                left_tip = roundedThird;
                left_interior_edge = height / 2;
                right_tip = roundedThird;
                right_exterior_edge = width;
                break;
        }

        const dimensions = {
            exterior_base,
            left_exterior_edge,
            left_tip,
            left_interior_edge,
            right_tip,
            right_exterior_edge,
        };
        return { ...BaseRoom, shape, obsoleteDimensions: dimensions };
    };

    private static createPlus = (width: number, height: number): IRoom => {
        const widthInCm = width * 100;
        const fixedWidthInCm = (widthInCm - widthInCm % 3);

        const roundedThirdHorizontalInMeter = fixedWidthInCm / 3 / 100; //avoid aving infinite decimals
        const fixedWidthInMeter = fixedWidthInCm / 100;
        const edgesHorizontal = (fixedWidthInMeter - roundedThirdHorizontalInMeter) / 2;

        const heightInCm = height * 100;
        const fixedHeightInCm = (heightInCm - heightInCm % 3);
        const roundedThirdVerticalInMeter = fixedHeightInCm / 3 / 100; //avoid aving infinite decimals

        const fixedHeightinMeter = fixedHeightInCm / 100;
        const edgesVertical = (fixedHeightinMeter - roundedThirdVerticalInMeter) / 2;
        return {
            ...BaseRoom,
            shape: RoomShape.Plus,
            customPoints: [
                { x: 0, y: 0 },
                { x: edgesHorizontal, y: 0 },
                { x: edgesHorizontal, y: edgesVertical },
                { x: edgesHorizontal + roundedThirdHorizontalInMeter, y: edgesVertical },
                { x: edgesHorizontal + roundedThirdHorizontalInMeter, y: edgesVertical + roundedThirdVerticalInMeter },
                { x: edgesHorizontal, y: edgesVertical + roundedThirdVerticalInMeter },
                { x: edgesHorizontal, y: edgesVertical * 2 + roundedThirdVerticalInMeter },
                { x: 0, y: edgesVertical * 2 + roundedThirdVerticalInMeter },
                { x: 0, y: edgesVertical + roundedThirdVerticalInMeter },
                { x: -edgesHorizontal, y: edgesVertical + roundedThirdVerticalInMeter },
                { x: -edgesHorizontal, y: edgesVertical },
                { x: 0, y: edgesVertical },
            ]
        };
    };
}
