import { ConfigurationUtils } from '../../../../Helpers/ConfigurationUtils';
import { QuestionContext } from '../../../../Helpers/QuestionContextHelper';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { ConfigurationReponseDto } from '../../../../Models/Projects/Configuration';
import { ProduitDto } from '../../../../Models/Questions/ProduitDto';
import { QuestionContextType, QuestionItem, QuestionStep } from '../../../../Models/Questions/QuestionDto';
import { ContextQuestionList, IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { OpeningType } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { RoomItemType } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { ContextQuestionsUtils } from '../Utils/ContextQuestionsUtils';

type PrepareItemStepQuestions = {
    itemId: string;
    item: IMapItem;
    questions: Array<QuestionItem>;

    produitPrincipal?: ProduitDto;

    contextType: QuestionContextType;
    openingType?: OpeningType;
    roomItemType?: RoomItemType;

    fromLoadingConfiguration?: boolean;
    reponses?: Array<ConfigurationReponseDto>;

    isVendeur?: boolean;
    withLog?: boolean;
};

type PrepareGeneralStepQuestions = {
    step: QuestionStep;
    questions: Array<QuestionItem>;
    contextQuestionList?: ContextQuestionList;

    previousContext?: QuestionContext;

    fromLoadingConfiguration?: boolean;
    reponses?: Array<ConfigurationReponseDto>;

    isVendeur?: boolean;
    withLog?: boolean;
};

type ApplyReponseStepQuestions = {
    contextId?: string;
    questions: QuestionItem[];
    reponses: ConfigurationReponseDto[],
    produitPrincipal?: ProduitDto
}

type PrepareStepQuestions = {
    stepQuestions: ContextQuestionList;
    context?: QuestionContext;
    produitPrincipal?: ProduitDto;
    withLog?: boolean;
    isVendeur?: boolean;
};

export interface ContextQuestions {
    stepQuestions: ContextQuestionList;
    initialContext?: QuestionContext;
    produitPrincipal?: ProduitDto;
    noFocusQuestion?: boolean;
    autoValidQuestionCommentType: boolean;
    withLog?: boolean;
}

export interface PrepareHabitationQuestions {
    questions: Array<QuestionItem>;
    initialContext?: QuestionContext;
    fromLoadingConfiguration?: boolean;
    noFocusQuestion?: boolean;
    autoValidQuestionCommentType: boolean;
    withLog?: boolean;
}

export interface PrepareStepQuestionsListParams {
    reponses: ConfigurationReponseDto[];
    questions?: QuestionItem[];
    fromLoadingConfiguration?: boolean;
    initialContext?: QuestionContext;
    noFocusQuestion?: boolean;
    autoValidQuestionCommentType: boolean;
    isVendeur?: boolean;
    withLog?: boolean;
}

export class QuestionPrepare {
    public static getPreviousStepContext = (item: IMapItem, step: QuestionStep, withLog?: boolean): QuestionContext => {
        const room = item as IRoom;
        if (step === QuestionStep.StepProduit) {
            return QuestionsHelper.getLastShownQuestion(item.questionsTva?.questions)?.lastShownQuestion?.context
                || this.getPreviousStepContext(item, QuestionStep.StepTva)
        }
        if (step === QuestionStep.StepPose) {
            return room.questionProduitPrincipal?.context
                || this.getPreviousStepContext(item, QuestionStep.StepProduit);
        }
        if (step === QuestionStep.StepSupport) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPose?.questions)?.lastShownQuestion?.context
                || this.getPreviousStepContext(item, QuestionStep.StepPose, withLog)
            );
        }
        if (step === QuestionStep.StepPreparation) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsSupport?.questions)?.lastShownQuestion?.context
                || this.getPreviousStepContext(item, QuestionStep.StepSupport, withLog)
            );
        }
        if (step === QuestionStep.StepFinitions) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPreparation?.questions)?.lastShownQuestion?.context
                || this.getPreviousStepContext(item, QuestionStep.StepPreparation, withLog)
            );
        }

        return item.questionsBaseContext || {};
    };

    public static getStepQuestions = (item: IMapItem, step: QuestionStep): ContextQuestionList | undefined => {
        if (step === QuestionStep.StepPose) {
            return item.questionsPose;
        }
        if (step === QuestionStep.StepSupport) {
            return item.questionsSupport;
        }
        if (step === QuestionStep.StepPreparation) {
            return item.questionsPreparation;
        }
        if (step === QuestionStep.StepFinitions) {
            return item.questionsFinition;
        }
        return undefined;
    };

    public static toStepQuestions = (questions: QuestionItem[], step: QuestionStep): ContextQuestionList => {
        const stepQuestions = questions.filter((q) => q.related_step === step);
        return { step, questions: stepQuestions };
    };

    public static updateItemStepsQuestions = ({
        itemId,
        item,
        contextType,
        openingType,
        roomItemType,
        questions = [],
        produitPrincipal,
        fromLoadingConfiguration,
        reponses = [],
        isVendeur,
        withLog
    }: PrepareItemStepQuestions) => {

        //#region //* STEP TVA

        const questionsTva = questions.filter((q) => q.related_step === QuestionStep.StepTva);
        const questionsTvaToUse =
            item.questionsTva ||
            ContextQuestionsUtils.toContextQuestions({
                step: QuestionStep.StepTva,
                questions: questionsTva,
                contextType,
            });
        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ contextId: itemId, questions: questionsTvaToUse.questions, reponses });
        }
        item.questionsTva = this.updateStepQuestions({
            stepQuestions: questionsTvaToUse,
            context: this.getPreviousStepContext(item, QuestionStep.StepTva),
            isVendeur,
        });

        //#endregion

        //#region //* STEP PRODUIT

        const room = item as IRoom;
        if (contextType === QuestionContextType.Rooms || contextType === QuestionContextType.Walls) {
            const roomQuestionProduitPrincipal = room.questionProduitPrincipal;
            if (roomQuestionProduitPrincipal) {
                QuestionsHelper.initializeQuestions({
                    questions: [roomQuestionProduitPrincipal],
                    initialContext: this.getPreviousStepContext(item, QuestionStep.StepProduit),
                });
            }
        }

        //#endregion

        //#region //* STEP POSE

        const questionsPose = questions.filter((q) => q.related_step === QuestionStep.StepPose);
        const questionsPoseToUse =
            item.questionsPose ||
            ContextQuestionsUtils.toContextQuestions({
                step: QuestionStep.StepPose,
                questions: questionsPose,
                contextType,
            });
        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ contextId: itemId, questions: questionsPoseToUse.questions, reponses, produitPrincipal });
        }
        item.questionsPose = this.updateStepQuestions({
            stepQuestions: questionsPoseToUse,
            context: this.getPreviousStepContext(item, QuestionStep.StepPose),
            produitPrincipal,
            isVendeur,
            withLog,
        });
        //#endregion

        //#region //* STEP SUPPORT

        const questionsSupport = questions.filter((q) => q.related_step === QuestionStep.StepSupport);
        const questionsSupportToUse =
            item.questionsSupport ||
            ContextQuestionsUtils.toContextQuestions({
                step: QuestionStep.StepSupport,
                questions: questionsSupport,
                contextType,
            });
        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ contextId: itemId, questions: questionsSupportToUse.questions, reponses, produitPrincipal });
        }
        item.questionsSupport = this.updateStepQuestions({
            stepQuestions: questionsSupportToUse,
            context: this.getPreviousStepContext(item, QuestionStep.StepSupport),
            produitPrincipal,
            isVendeur,
        });

        //#endregion

        //#region //* STEP PREPARATION

        const questionsPreparation = questions.filter((q) => q.related_step === QuestionStep.StepPreparation);
        const questionsPreparationToUse =
            item.questionsPreparation ||
            ContextQuestionsUtils.toContextQuestions({
                step: QuestionStep.StepPreparation,
                questions: questionsPreparation,
                contextType,
            });
        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ contextId: itemId, questions: questionsPreparationToUse.questions, reponses, produitPrincipal });
        }
        item.questionsPreparation = this.updateStepQuestions({
            stepQuestions: questionsPreparationToUse,
            context: this.getPreviousStepContext(item, QuestionStep.StepPreparation),
            produitPrincipal,
            isVendeur,
        });

        //#endregion

        //#region //* STEP FINITION

        const questionsFinition = questions.filter((q) => q.related_step === QuestionStep.StepFinitions);
        const questionsFinitionToUse =
            item.questionsFinition ||
            ContextQuestionsUtils.toContextQuestions({
                step: QuestionStep.StepFinitions,
                questions: questionsFinition,
                contextType,
                openingType,
                roomItemType,
            });
        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ contextId: itemId, questions: questionsFinitionToUse.questions, reponses, produitPrincipal });
        }
        item.questionsFinition = this.updateStepQuestions({
            stepQuestions: questionsFinitionToUse,
            context: this.getPreviousStepContext(item, QuestionStep.StepFinitions),
            produitPrincipal,
            isVendeur,
        });

        //#endregion
    };

    public static updateGeneralStepsQuestions = ({
        step,
        contextQuestionList,
        questions = [],
        previousContext = {},
        fromLoadingConfiguration,
        reponses = [],
        isVendeur,
        withLog
    }: PrepareGeneralStepQuestions) => {
        const stepQuestions = questions.filter((q) => q.related_step === step);
        const stepQuestionsToUse = contextQuestionList ||
            ContextQuestionsUtils.toContextQuestions({ step: QuestionStep.StepTva, questions: stepQuestions, contextType: QuestionContextType.General });

        if (fromLoadingConfiguration) {
            this.applyReponseStepQuestions({ questions: stepQuestionsToUse.questions, reponses });
        }
        return this.updateStepQuestions({
            stepQuestions: stepQuestionsToUse,
            context: previousContext,
            isVendeur,
        });
    };

    public static applyReponseStepQuestions = ({ contextId, questions = [], reponses = [], produitPrincipal }
        : ApplyReponseStepQuestions) => {
        questions?.forEach((question: QuestionItem) => {
            ConfigurationUtils.applyReponseToQuestion({
                contextId,
                produitPrincipal,
                question: question,
                reponses,
            });
        });
    };

    public static updateStepQuestions = ({
        stepQuestions,
        context = {},
        produitPrincipal,
        withLog,
        isVendeur,
    }: PrepareStepQuestions): ContextQuestionList => {
        stepQuestions.initialContext = context;
        const autoValidQuestionCommentType = false;
        const questions = stepQuestions?.questions || [];
        if (questions.length > 0) {
            QuestionsHelper.validateCommentAndRespirationQuestionIfNeeded(questions, autoValidQuestionCommentType);
            QuestionsHelper.initializeQuestions({ questions, produitPrincipal, initialContext: context, withLog });
            const findIncompatibility = QuestionsHelper.findFirstIncompatibility(questions);
            let { isStepCompleted, isEmpty } = QuestionsHelper.isStepCompleted({
                questions: questions,
                produitPrincipal,
                isVendeur,
            });

            //* update questions and context
            stepQuestions.questions = questions;
            stepQuestions.endQuestion = findIncompatibility.findIncompatibility ? false : isStepCompleted;
            stepQuestions.isEmpty = isEmpty;
            stepQuestions.findIncompatibility = findIncompatibility.findIncompatibility
                ? findIncompatibility
                : undefined;
        } else {
            stepQuestions.questions = questions;
            stepQuestions.endQuestion = true;
            stepQuestions.isEmpty = true;
            stepQuestions.findIncompatibility = undefined;
        }
        return stepQuestions;
    };

    public static updatedStepQuestionsFromLoadConfig = ({
        questions = [],
        initialContext = {},
        reponses = [],
        autoValidQuestionCommentType,
        fromLoadingConfiguration,
    }: PrepareStepQuestionsListParams) => {
        if (questions.length > 0) {
            if (fromLoadingConfiguration) {
                //* load questions habitation response
                questions.forEach((question: QuestionItem) =>
                    ConfigurationUtils.applyReponseToQuestion({ question, reponses })
                );
            }

            QuestionsHelper.validateCommentAndRespirationQuestionIfNeeded(questions, autoValidQuestionCommentType);
            QuestionsHelper.initializeQuestions({ questions, initialContext });

            const invalidAnswerIfIncompatiblityDetected = (questions: Array<QuestionItem>): Array<QuestionItem> => {
                //* on regarde s'il y a une question avec une incompatibilité
                //* si oui, on reset la réponse associée
                const { findIncompatibility, questionWithIncompatibility } =
                    QuestionsHelper.findFirstIncompatibility(questions);
                if (findIncompatibility && questionWithIncompatibility) {
                    const { updatedQuestion } = QuestionsHelper.invalidValueOnQuestion({
                        question: questionWithIncompatibility,
                    });
                    return QuestionsHelper.updateQuestions(questions, updatedQuestion);
                }
                return questions;
            };
            invalidAnswerIfIncompatiblityDetected(questions);
        }
    };

    //#region //* SECTION

    public static allPreviousStepCompleted = (item: IMapItem, step: QuestionStep): boolean | undefined => {
        const prevStep = this.getPreviousStep(item, step);
        const prevStepEnd = prevStep ? prevStep?.endQuestion : true;
        if (step === QuestionStep.StepSupport) {
            return prevStepEnd && this.allPreviousStepCompleted(item, QuestionStep.StepPose);
        }
        if (step === QuestionStep.StepPreparation) {
            return prevStepEnd && this.allPreviousStepCompleted(item, QuestionStep.StepSupport);
        }
        if (step === QuestionStep.StepFinitions) {
            return prevStepEnd && this.allPreviousStepCompleted(item, QuestionStep.StepPreparation);
        }
        return true;
    };

    public static isStepQuestionsOpened = (item?: IMapItem, step?: QuestionStep) => {
        if (step === QuestionStep.StepPose) {
            return item?.openSections?.questionsPose;
        }
        if (step === QuestionStep.StepSupport) {
            return item?.openSections?.questionsSupport;
        }
        if (step === QuestionStep.StepPreparation) {
            return item?.openSections?.questionsPreparation;
        }
        if (step === QuestionStep.StepFinitions) {
            return item?.openSections?.questionsFinition;
        }
        return item?.openSections?.infos;
    };

    public static getPreviousStep = (item: IMapItem, step: QuestionStep): ContextQuestionList | undefined => {
        if (step === QuestionStep.StepSupport) {
            return item.questionsPose?.endQuestion ? this.getPreviousStep(item, QuestionStep.StepPose) : item.questionsPose;
        }
        if (step === QuestionStep.StepPreparation) {
            return item.questionsSupport?.endQuestion
                ? this.getPreviousStep(item, QuestionStep.StepSupport)
                : item.questionsSupport;
        }
        if (step === QuestionStep.StepFinitions) {
            return item.questionsPreparation?.endQuestion
                ? this.getPreviousStep(item, QuestionStep.StepPreparation)
                : item.questionsPreparation;
        }

        return undefined;
    };

    public static getNextStep = (item: IMapItem, step: QuestionStep): ContextQuestionList | undefined => {
        if (step === QuestionStep.Infos) {
            return item.questionsPose?.endQuestion ? this.getNextStep(item, QuestionStep.StepPose) : item.questionsPose;
        }
        if (step === QuestionStep.StepPose) {
            return item.questionsSupport?.endQuestion
                ? this.getNextStep(item, QuestionStep.StepSupport)
                : item.questionsSupport;
        }
        if (step === QuestionStep.StepSupport) {
            return item.questionsPreparation?.endQuestion
                ? this.getNextStep(item, QuestionStep.StepPreparation)
                : item.questionsPreparation;
        }
        if (step === QuestionStep.StepPreparation) {
            return item.questionsFinition?.endQuestion
                ? this.getNextStep(item, QuestionStep.StepFinitions)
                : item.questionsFinition;
        }
        return undefined;
    };

    //#endregion
}
