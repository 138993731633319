import * as _ from 'lodash';

import { Logger } from '../../../../Errors/Logger';
import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { OpeningFactory } from '../../Helpers/BaseOpeningFactory';
import { RoomItemFactory } from '../../Helpers/BaseRoomItemFactory';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom, RoomLocalisation, RoomShape, RoomType } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { IdGenerator } from '../IdGenerator';
import { OpeningUtils } from '../Opening/OpeningUtils';
import { PointUtils } from '../PointUtils';
import { CoordPoint, Wall } from '../Types';
import { WallUtils } from '../Wall/WallUtils';
import { qSVG } from '../qSVG';

type CreateResult = { room: IRoom; roomWalls?: Array<Wall> };
export class CreateRoomFactory {
    public static createRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        room.roomId = room.roomId ?? IdGenerator.generate();

        if (room.type === RoomType.Wall) {
            room.localisation = RoomLocalisation.Inside;
        }
        if (room.coords) {
            return this.createCustomShapeRoom(room, currentWalls);
        } else {
            //! ⬇️ obsolete kept for v2 export compat
            switch (room.shape) {
                case RoomShape.Rectangle:
                    return this.createRectangleShapeRoom(room, currentWalls);
                case RoomShape.Diamond_topleft:
                case RoomShape.Diamond_topright:
                case RoomShape.Diamond_bottomleft:
                case RoomShape.Diamond_bottomright:
                    return this.createDiamondShapeRoom(room, currentWalls);
                case RoomShape.Flyingwing_topleft:
                case RoomShape.Flyingwing_topright:
                case RoomShape.Flyingwing_bottomleft:
                case RoomShape.Flyingwing_bottomright:
                    return this.createFlyingWingShapeRoom(room, currentWalls);
                case RoomShape.U_top:
                case RoomShape.U_right:
                case RoomShape.U_bottom:
                case RoomShape.U_left:
                    return this.createUShapeRoom(room, currentWalls);
                case RoomShape.Plus:
                    return this.createPlusShapeRoom(room, currentWalls);

                case RoomShape.Custom:
                    return this.createCustomShapeRoom(room, currentWalls);
            }
        }
        return { room, roomWalls: [] };
    };

    public static exportRoom = (room: IRoom): IRoom => {
        return this.exportCustomShapeRoom(room)!;
    };

    public static duplicate(
        room: IRoom,
        name: string,
        walls: Array<Wall>,
        openings: Array<IOpening>,
        roomItems: Array<IRoomItem>
    ): CreateResult {
        let exportedRoom: IRoom = this.exportRoom(room);
        if (exportedRoom) {
            exportedRoom.roomId = IdGenerator.generate();
            exportedRoom.name = name;
            exportedRoom.contournements = ObjectUtils.clone(exportedRoom.contournements);

            const bbox = PointUtils.calculateBoundingBox({ points: room.coords! })!;
            const overallBbox = PointUtils.calculateBoundingBox({ walls })!;
            const offset = {
                x: qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: overallBbox.xMax, y: bbox.yMin }) / 100 + 1.8,
                y: 0,
            };
            const offsetInCm = {
                x: offset.x * 100,
                y: 0,
            };
            const centerPositionOffset = PointUtils.translation(
                { x: exportedRoom.center!.x, y: exportedRoom.center!.y },
                offset
            );
            exportedRoom.coords = exportedRoom.coords?.map((x) => PointUtils.translation(x, offsetInCm));
            exportedRoom.center = centerPositionOffset;
            PointUtils.smoothOutPoint(exportedRoom.center);

            const exportedRoomOpenings = OpeningUtils.updateRoomOpenings(room, openings);
            exportedRoom.openings = ObjectUtils.clone(exportedRoomOpenings);
            const newOpenings = ObjectUtils.clone(openings);
            for (const opening of exportedRoom.openings!) {
                opening.openingId = IdGenerator.generate();
                opening.roomId = exportedRoom.roomId;
                opening.name = OpeningFactory.getNewName(opening, newOpenings);
                newOpenings.push(opening);
                //!maybe set opening position here,
                //! do not set location_position_from_edge (and avoid using fixPositionOpenings mehtod in OpeningUtils when import/or add room)
            }

            exportedRoom.roomItems = ObjectUtils.clone(exportedRoom.roomItems);
            const newRoomItems = ObjectUtils.clone(roomItems);
            for (const roomItem of exportedRoom.roomItems!) {
                roomItem.roomItemId = IdGenerator.generate();
                roomItem.roomId = exportedRoom.roomId;

                const positionOffset = { ...offset };
                positionOffset.x = offset.x * 100;
                roomItem.coordsReal = roomItem.coordsReal?.map((x) => PointUtils.translation(x, positionOffset));
                roomItem.graphTranslation = PointUtils.translation(roomItem.graphTranslation!, positionOffset);
                roomItem.name = RoomItemFactory.getNewName(roomItem, newRoomItems);
                newRoomItems.push(roomItem);
            }

            //importRoomFunc(exportedRoom);
            return this.createRoom(exportedRoom, walls);
        }

        //refreshWallsMinimumSizeAndOriginalAngle(ROOM[ROOM.length - 1].walls);
        //WallUtils.refreshWallsMinimumSizeAndOriginalAngle(walls, newOpenings);

        return { room: exportedRoom! };
    }

    //#region //* CREATE ROOM SHAPE

    private static createRectangleShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            points = [
                { x: 0, y: 0 },
                { x: room.obsoleteDimensions!.width!, y: 0 },
                { x: room.obsoleteDimensions!.width!, y: room.obsoleteDimensions!.height! },
                { x: 0, y: room.obsoleteDimensions!.height! },
            ];
            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }
            points.forEach((point) => PointUtils.convertToCoordinates(point));
            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }
            PointUtils.smoothOutPoints(points);
        }

        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], 'top', room),
            WallUtils.createWall(points[1], points[2], 'right', room),
            WallUtils.createWall(points[2], points[3], 'bottom', room),
            WallUtils.createWall(points[3], points[0], 'left', room),
        ];
        return { room, roomWalls };
    };

    private static createDiamondShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            const angleShape = new Map([
                [RoomShape.Diamond_topleft, 0],
                [RoomShape.Diamond_topright, 90],
                [RoomShape.Diamond_bottomright, 180],
                [RoomShape.Diamond_bottomleft, 270],
            ]);

            //* define points coordinates
            const tableCommonPoint = Math.sqrt(Math.pow(room.obsoleteDimensions!.table!, 2) / 2);

            points = [
                { x: 0, y: tableCommonPoint },
                { x: tableCommonPoint, y: 0 },
                { x: tableCommonPoint + room.obsoleteDimensions!.right_crown!, y: 0 },
                {
                    x: tableCommonPoint + room.obsoleteDimensions!.right_crown!,
                    y: tableCommonPoint + room.obsoleteDimensions!.left_crown!,
                },
                { x: 0, y: tableCommonPoint + room.obsoleteDimensions!.left_crown! },
            ];

            PointUtils.rotation(points, angleShape.get(room.shape!)!);

            //* move points to correct location
            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            //* convert meter to svg coordinates
            points.forEach((point) => PointUtils.convertToCoordinates(point, true));

            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }

            PointUtils.smoothOutPoints(points);
        }

        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [
            //* draw table
            WallUtils.createWall(points[0], points[1], 'table', room),
            //* draw right crown
            WallUtils.createWall(points[1], points[2], 'right_crown', room),
            //* draw right pavilion
            WallUtils.createWall(points[2], points[3], 'right_pavilion', room),
            //* draw left pavilion
            WallUtils.createWall(points[3], points[4], 'left_pavilion', room),
            //* draw left crown
            WallUtils.createWall(points[4], points[0], 'left_crown', room),
        ];
        return { room, roomWalls };
    };

    private static createFlyingWingShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const angleShape = new Map([
            ['flyingwing_topleft', 0],
            ['flyingwing_topright', 90],
            ['flyingwing_bottomright', 180],
            ['flyingwing_bottomleft', 270],
        ]);

        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            points = [
                { x: 0, y: 0 },
                { x: room.obsoleteDimensions!.left_wingtip! + room.obsoleteDimensions!.right_trailing_edge!, y: 0 },
                {
                    x: room.obsoleteDimensions!.left_wingtip! + room.obsoleteDimensions!.right_trailing_edge!,
                    y: room.obsoleteDimensions!.right_wingtip!,
                },
                { x: room.obsoleteDimensions!.left_wingtip!, y: room.obsoleteDimensions!.right_wingtip! },
                {
                    x: room.obsoleteDimensions!.left_wingtip!,
                    y: room.obsoleteDimensions!.right_wingtip! + room.obsoleteDimensions!.left_trailing_edge!,
                },
                { x: 0, y: room.obsoleteDimensions!.right_wingtip! + room.obsoleteDimensions!.left_trailing_edge! },
            ];

            PointUtils.rotation(points, angleShape.get(room.shape!)!);

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            points.forEach((point) => PointUtils.convertToCoordinates(point));

            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }
            PointUtils.smoothOutPoints(points);
        }

        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], 'right_leading_edge', room),
            WallUtils.createWall(points[1], points[2], 'right_wingtip', room),
            WallUtils.createWall(points[2], points[3], 'right_trailing_edge', room),
            WallUtils.createWall(points[3], points[4], 'left_trailing_edge', room),
            WallUtils.createWall(points[4], points[5], 'left_wingtip', room),
            WallUtils.createWall(points[5], points[0], 'left_leading_edge', room),
        ];
        return { room, roomWalls };
    };

    private static createUShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const angleShape = new Map([
            [RoomShape.U_top, 0],
            [RoomShape.U_right, 90],
            [RoomShape.U_bottom, 180],
            [RoomShape.U_left, 270],
        ]);
        const exteriorBase = room.obsoleteDimensions!.exterior_base!;
        const leftExteriorEdge = room.obsoleteDimensions!.left_exterior_edge!;
        const leftTip = room.obsoleteDimensions!.left_tip!;
        const leftInteriorEdge = room.obsoleteDimensions!.left_interior_edge!;
        const rightTip = room.obsoleteDimensions!.right_tip!;
        const rightExteriorEdge = room.obsoleteDimensions!.right_exterior_edge!;

        //* define points coordinates
        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            points = [
                { x: 0, y: 0 },
                { x: leftTip, y: 0 },
                { x: leftTip, y: leftInteriorEdge },
                { x: exteriorBase - rightTip, y: leftInteriorEdge },
                { x: exteriorBase - rightTip, y: leftExteriorEdge - rightExteriorEdge },
                { x: exteriorBase, y: leftExteriorEdge - rightExteriorEdge },
                { x: exteriorBase, y: leftExteriorEdge },
                { x: 0, y: leftExteriorEdge },
            ];

            PointUtils.rotation(points, angleShape.get(room.shape!)!);

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            points.forEach((point) => PointUtils.convertToCoordinates(point));

            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }
            PointUtils.smoothOutPoints(points);
        }

        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], 'left_tip', room),
            WallUtils.createWall(points[1], points[2], 'left_interior_edge', room),
            WallUtils.createWall(points[2], points[3], 'interior_base', room),
            WallUtils.createWall(points[3], points[4], 'right_interior_edge', room),
            WallUtils.createWall(points[4], points[5], 'right_tip', room),
            WallUtils.createWall(points[5], points[6], 'right_exterior_edge', room),
            WallUtils.createWall(points[6], points[7], 'exterior_base', room),
            WallUtils.createWall(points[7], points[0], 'left_exterior_edge', room),
        ];
        return { room, roomWalls };
    };

    private static createPlusShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        //* define points coordinates

        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            points = [
                { x: 0, y: 0 },
                { x: 1.5, y: 0 },
                { x: 1.5, y: 1.5 },
                { x: 3.0, y: 1.5 },
                { x: 3.0, y: 3.0 },
                { x: 1.5, y: 3.0 },
                { x: 1.5, y: 4.5 },
                { x: 0, y: 4.5 },
                { x: 0, y: 3.0 },
                { x: -1.5, y: 3.0 },
                { x: -1.5, y: 1.5 },
                { x: 0, y: 1.5 },
            ];

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            points.forEach((point) => PointUtils.convertToCoordinates(point));

            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }
            PointUtils.smoothOutPoints(points);
        }

        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], 'top_tip', room),
            WallUtils.createWall(points[1], points[2], 'top_right', room),
            WallUtils.createWall(points[2], points[3], 'right_left', room),
            WallUtils.createWall(points[3], points[4], 'right_tip', room),
            WallUtils.createWall(points[4], points[5], 'right_right', room),
            WallUtils.createWall(points[5], points[6], 'bottom_left', room),
            WallUtils.createWall(points[6], points[7], 'bottom_tip', room),
            WallUtils.createWall(points[7], points[8], 'bottom_right', room),
            WallUtils.createWall(points[8], points[9], 'left_left', room),
            WallUtils.createWall(points[9], points[10], 'left_tip', room),
            WallUtils.createWall(points[10], points[11], 'left_right', room),
            WallUtils.createWall(points[11], points[0], 'top_left', room),
        ];

        return { room, roomWalls };
    };

    private static createCustomShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const points = _.cloneDeep(room.coords?.slice(0, room.coords!.length - 1) || room.customPoints || []);
        if (!room.coords) {
            const walls = PointUtils.toWalls(points);

            const sides = new Map();
            let wallCount = 0;
            walls.forEach((wall) => {
                sides.set(`side_${wallCount}`, [wall.start, wall.end]);
                wallCount++;
            });
            room.sides = sides;

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            points.forEach((point) => PointUtils.convertToCoordinates(point));

            PointUtils.smoothOutPoints(points);
        }
        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [];
        for (let i = 0; i < points.length; i++) {
            roomWalls.push(WallUtils.createWall(points[i], points[(i + 1) % points.length], `side_${i}`, room));
        }

        Logger.log('createCustomShapeRoom', { room, roomWalls });
        return { room, roomWalls };
    };

    //#endregion

    private static exportCustomShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));
        const roomWalls = PointUtils.toWalls(points);

        const sides = new Map();
        let wallCount = 0;
        roomWalls.forEach((wall) => {
            sides.set(`side_${wallCount}`, [wall.start, wall.end]);
            wallCount++;
        });

        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        return this.exportRoomObject(room, mp, points);
    }

    private static exportRoomObject(room: IRoom, center: CoordPoint, points?: Array<CoordPoint>): IRoom {
        return {
            ...room,
            center: center,
            customPoints: points,
        };
    }

    //#endregion

    //#region //* GET ROOM SIDES

    public static toRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map();
    };

    //#endregion

    public static convertToMeters(point: CoordPoint) {
        point.x /= MapConstants.meter;
        point.y /= MapConstants.meter;
        return point;
    }
}
