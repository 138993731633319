import { useGestureContext } from '../../Context/GestureContext';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { Editor } from '../Editor';
import { useMagnetize } from '../MagnetizedUtils';
import { OpeningUtils } from '../Opening/OpeningUtils';
import { PointUtils } from '../PointUtils';
import { qSVG } from '../qSVG';
import { CoordPoint, SnapGesture, Wall } from '../Types';

export const numbersRegex: RegExp = /(-?\d+(\.\d+)?(e-?[0-9]\d)?)/g;
let translateX: number;
let translateY: number;

export type RoomMoveInit = { x1: number; y1: number; x2: number; y2: number };

export class RoomMove {
    public static class = (roomId: string) => `room-${roomId}`;

    public static BASE_WALLS_ROOM_ID = 'walls-room';
    public static roomWallsId = (roomId: string) => `${RoomMove.BASE_WALLS_ROOM_ID}-${roomId}`;

    public static BASE_RIBS_ROOM_ID = 'ribs-room';
    public static roomRibsId = (roomId: string) => `${RoomMove.BASE_RIBS_ROOM_ID}-${roomId}`;

    public static BASE_ROOM_NAME_ROOM_ID = 'nameArea-room';
    public static roomAreaId = (roomId: string) => `${RoomMove.BASE_ROOM_NAME_ROOM_ID}-${roomId}`;

    public static BASE_OPENING_IN_ROOM_ID = 'opening-room';
    public static openingRoomId = (roomId: string) => `${RoomMove.BASE_OPENING_IN_ROOM_ID}-${roomId}`;

    public static BASE_ROOM_ITEM_IN_ROOM_ID = 'roomItem-room';
    public static roomItemId = (roomId: string) => `${RoomMove.BASE_ROOM_ITEM_IN_ROOM_ID}-${roomId}`;

    public static BASE_ROOM_ITEM_RIBS_ID = 'roomItem-ribs';
    public static roomItemRibsId = (roomId: string, roomItemId: string) =>
        `${RoomMove.BASE_ROOM_ITEM_RIBS_ID}-${roomId}-${roomItemId}`;

    public static BASE_TRACE_BAND = 'trace-band';
    public static roomTraceBandId = (roomId: string, name?: string) => `${RoomMove.BASE_TRACE_BAND}-${roomId}-${name}`;
}

export const useRoomMove = () => {
    const magnetize = useMagnetize();
    const gesture = useGestureContext();
    const pressDownRoom = (snap: SnapGesture, room: IRoom): RoomMoveInit => {
        const clientX = snap.x;
        const clientY = snap.y;

        return { x1: clientX, y1: clientY, x2: clientX, y2: clientY };
    };
    const moveRoom = (snap: SnapGesture, room: IRoom, roomMoveInit: RoomMoveInit, rooms: Array<IRoom>) => {
        const forcedSnap = magnetize.magnetizedRooms(
            snap,
            room,
            { x: translateX, y: translateY },
            { x: roomMoveInit.x2, y: roomMoveInit.y2 },
            rooms
        );
        if (forcedSnap.x) {
            snap.x = forcedSnap.x;
        }
        if (forcedSnap.y) {
            snap.y = forcedSnap.y;
        }

        const els = document.getElementsByClassName(RoomMove.class(room.roomId!));
        //let hasContact = false;

        const tolerance_round_value = MapConstants.tolerance_round_value;

        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            translateX = snap.x - roomMoveInit.x1;
            translateY = snap.y - roomMoveInit.y1;
            const vector = { x: translateX, y: translateY };
            const newCenter = PointUtils.translation({ x: roomMoveInit.x1, y: roomMoveInit.y1 }, vector);
            room.center = { x: newCenter.x / MapConstants.meter, y: newCenter.y / MapConstants.meter };
            PointUtils.smoothOutPoint(room.center);

            translateX = translateX > -tolerance_round_value && translateX < tolerance_round_value ? 0 : translateX; //avoid 7.105427357601002e-15 like value that doesnot convert correctly
            translateY = translateY > -tolerance_round_value && translateY < tolerance_round_value ? 0 : translateY; //avoid 7.105427357601002e-15 like value that doesnot convert correctly
            const t = `translate(${translateX},${translateY})`;
            el.setAttribute('transform', t);
        }
    };

    const pressUpRoom = (
        room: IRoom,
        rooms: Array<IRoom>,
        openings: Array<IOpening>,
        roomItems: Array<IRoomItem>,
        walls: Array<Wall>
    ) => {
        const roomCotesEl = document.getElementById(RoomMove.roomRibsId(room.roomId!))!;
        const roomWallsEl = document.getElementById(RoomMove.roomWallsId(room.roomId!))!;
        const roomAreaEl = document.getElementById(RoomMove.roomAreaId(room.roomId!))!;

        if ((gesture.state.invalidRoomIds ?? []).length > 0) {
            gesture.update({ invalidRoomIds: undefined });
            const openingEl = document.getElementById(RoomMove.openingRoomId(room.roomId!))!;
            const roomItemEl = document.getElementById(RoomMove.roomItemId(room.roomId!))!;
            openingEl?.removeAttribute('transform');
            roomItemEl?.removeAttribute('transform');
            roomCotesEl?.removeAttribute('transform');
            roomWallsEl?.removeAttribute('transform');
            roomAreaEl?.removeAttribute('transform');
            Editor.architect(walls, rooms);
            openings = OpeningUtils.resetOpeningsPlacement(openings, walls);
            return { updatedRooms: rooms, updatedOpenings: openings, updatedRoomItems: roomItems, updatedWalls: walls };
        }

        if (roomWallsEl.hasAttribute('transform')) {
            const transform = roomWallsEl.getAttribute('transform')!;
            const rawVector = transform.match(numbersRegex)!;
            const vector = { x: Number(rawVector[0]), y: Number(rawVector[1]) };

            //* UPDATE ROOM OPENINGS COORDS
            room.walls!.forEach((wall) => {
                const roomOpeningInWall = Editor.objFromWallRoom(wall, openings);
                roomOpeningInWall.forEach((opening) => {
                    const newCoordinates = PointUtils.translation(opening as CoordPoint, vector);
                    opening.x = newCoordinates.x;
                    opening.y = newCoordinates.y;
                    const limits = PointUtils.limitObj(wall.equations.base, opening.size!, opening as CoordPoint);
                    opening.angle = qSVG.angleDeg(wall.start.x, wall.start.y, wall.end.x, wall.end.y);
                    opening.limit = limits;
                });
                wall.start = PointUtils.translation(wall.start, vector);
                wall.end = PointUtils.translation(wall.end, vector);
            });

            //* UPDATE ROOM ITEMS COORDS
            room.roomItems!.forEach((roomItem) => {
                const currentTranslate = roomItem.graphTranslation!;
                const vector: CoordPoint = { x: translateX || 0, y: translateY || 0 };
                roomItem.coordsReal = roomItem.coordsReal!.map((p) => PointUtils.translation(p, vector));
                roomItem.graphTranslation = { x: currentTranslate.x + vector.x, y: currentTranslate.y + vector.y };
            });
        }

        translateX = 0;
        translateY = 0;
        roomCotesEl?.removeAttribute('transform');
        roomWallsEl?.removeAttribute('transform');
        roomAreaEl?.removeAttribute('transform');

        const els = document.getElementsByClassName(RoomMove.class(room.roomId!));
        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            if (
                el.id.startsWith(RoomMove.BASE_WALLS_ROOM_ID) ||
                el.id.startsWith(RoomMove.BASE_RIBS_ROOM_ID) ||
                el.id.startsWith(RoomMove.BASE_ROOM_NAME_ROOM_ID) ||
                el.id.startsWith(RoomMove.BASE_OPENING_IN_ROOM_ID) ||
                el.id.startsWith(RoomMove.BASE_ROOM_ITEM_IN_ROOM_ID) ||
                el.id.startsWith(RoomMove.BASE_ROOM_ITEM_RIBS_ID) ||
                el.id.startsWith(RoomMove.BASE_TRACE_BAND)
            ) {
                el.removeAttribute('transform');
            }
        }

        const updatedRooms = rooms.map((r) => (r.roomId === room.roomId ? room : r));
        const updatedWalls = updatedRooms.reduce<Array<Wall>>((items, c) => [...items, ...c.walls!], []);
        let updatedOpenings = updatedRooms.reduce<Array<IOpening>>((items, c) => [...items, ...c.openings!], []);
        const updatedRoomItems = updatedRooms.reduce<Array<IRoomItem>>((items, c) => [...items, ...c.roomItems!], []);

        Editor.architect(updatedWalls, updatedRooms);
        updatedOpenings = OpeningUtils.resetOpeningsPlacement(updatedOpenings, updatedWalls);

        for (const room of updatedRooms) {
            room.openings = OpeningUtils.updateRoomOpenings(room, updatedOpenings);
        }

        return { updatedRooms, updatedOpenings, updatedRoomItems, updatedWalls };
    };

    return { pressDownRoom, moveRoom, pressUpRoom };
};
