import { QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { FindFirstIncompatibilityResult, QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { QuestionContextType, QuestionItem, QuestionStep } from '../../../../Models/Questions/QuestionDto';
import { MapContextState } from '../../../../Plugins/FloorPlan/Context/MapContext';
import { CompleteHelper } from '../../../../Plugins/FloorPlan/Helpers/CompleteHelper';
import { IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom, RoomType } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { EnhancedViewState } from '../../../Hooks/ViewState/ViewState';
import { QuestionPrepare } from '../Helpers/QuestionPrepare';
import { QuoteState } from '../QuoteState';
import { QuestionContextUtils } from './QuestionContextUtils';

export const toUpdateMapItemQuestions = (
    mapState: MapContextState,
    quoteState: EnhancedViewState<QuoteState>,
    isVendeur?: boolean
): EnhancedViewState<MapContextState> => {
    //!\ WHEN UPDATE rooms, openings, roomItems, from Panels
    const { questions = [], stepHabitation } = quoteState;
    const { rooms = [], openings = [], roomItems = [], laizeResults = [] } = mapState;

    const getIncompatibility = (item: IMapItem, questionProduit?: QuestionItem) => {
        const questions = questionProduit ? [questionProduit] : [];
        questions.push(
            ...(item.questionsPose?.questions! || []),
            ...(item.questionsSupport?.questions! || []),
            ...(item.questionsPreparation?.questions! || []),
            ...(item.questionsFinition?.questions! || []),
        );
        return QuestionsHelper.findFirstIncompatibility(questions);
    };

    const updateIncompatibility = (item: IMapItem, firstIncompatibility: FindFirstIncompatibilityResult) => {
        if (firstIncompatibility.findIncompatibility) {
            item.findIncompatibilityResult = firstIncompatibility;
            rooms.find((r) => r.roomId === item.roomId)!.findIncompatibilityResult = firstIncompatibility;
        } else {
            item.findIncompatibilityResult = undefined;
        }
    };

    if (rooms.length || openings.length || roomItems.length) {
        rooms.forEach((item: IRoom) => {
            const questionProduitPrincipal = item.questionProduitPrincipal!;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextHelper.mergeContext(
                QuestionContextHelper.getBaseQuestionsContext(stepHabitation?.questions),
                QuestionContextUtils.createRoomContext(item, flooringDirection)
            );
            QuestionPrepare.updateItemStepsQuestions({
                itemId: item.roomId!,
                item,
                questions,
                produitPrincipal,
                contextType: item.type === RoomType.Sol ? QuestionContextType.Rooms : QuestionContextType.Walls,
                isVendeur,
            });
            let firstIncompatibility = getIncompatibility(item, item.questionProduitPrincipal!);
            item.findIncompatibilityResult = firstIncompatibility.findIncompatibility
                ? firstIncompatibility
                : undefined;
        });

        openings.forEach((item: IOpening) => {
            const room = rooms.find((r) => r.roomId === item.roomId)!;
            const questionProduitPrincipal = room.questionProduitPrincipal;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextUtils.createOpeningContext(item, room, flooringDirection);
            QuestionPrepare.updateItemStepsQuestions({
                itemId: item.openingId!,
                item,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.Openings,
                openingType: item.type,
                isVendeur,
            });
            updateIncompatibility(item, getIncompatibility(item));
            item.completionStatus = CompleteHelper.isOpeningComplete(item, openings);
        });

        roomItems.forEach((item: IRoomItem) => {
            const room = rooms.find((r) => r.roomId === item.roomId)!;
            const questionProduitPrincipal = room.questionProduitPrincipal;
            const produitPrincipal = questionProduitPrincipal?.produitValue;
            const flooringDirection = laizeResults.find((x) => x.roomIds?.some((y) => y === item.roomId))
                ?.flooringDirectionByRoomId?.[item.roomId!];

            item.questionsBaseContext = QuestionContextUtils.createRoomItemContext(item, room, flooringDirection);
            QuestionPrepare.updateItemStepsQuestions({
                itemId: item.roomItemId!,
                item,
                questions,
                produitPrincipal,
                contextType: QuestionContextType.RoomElements,
                roomItemType: item.type,
                isVendeur,
            });

            updateIncompatibility(item, getIncompatibility(item));
            item.completionStatus = CompleteHelper.isRoomItemComplete(item, roomItems);
        });

        rooms.forEach((item: IRoom) => (item.completionStatus = CompleteHelper.isRoomComplete(item, rooms)));

        const roomsCompleted = CompleteHelper.allComplete(rooms);
        return { modified: true, rooms, openings, roomItems, roomsCompleted };
    }
    return { modified: false, roomsCompleted: false };
};

export const toUpdateGeneralQuestions = (mapState: MapContextState, quoteState: QuoteState, isVendeur?: boolean): EnhancedViewState<QuoteState> => {
    const { questions = [], stepHabitation, stepServices, stepSynthese } = quoteState;
    const { rooms = [] } = mapState;
    const roomsSol = rooms.filter(x => x.type === RoomType.Sol);
    const roomsWall = rooms.filter(x => x.type === RoomType.Wall);

    const stepHabitationContext = QuestionContextHelper.getBaseQuestionsContext(stepHabitation?.questions);
    const optionsBaseContext = QuestionContextHelper.mergeContext(stepHabitationContext, {
        evaluations: {
            [QuestionContextHelper.ragreageObligatoreKey]: QuestionContextHelper.isRagreageObligatoireExist(rooms) ? QuestionContextHelper.ragreageObligatoreValue : '',
        },
        rooms: roomsSol.map(room => QuestionContextUtils.getLastStepContext(room, QuestionStep.StepFinitions)),
        walls: roomsWall.map(room => QuestionContextUtils.getLastStepContext(room, QuestionStep.StepFinitions))
    });
    QuestionPrepare.updateGeneralStepsQuestions({
        step: QuestionStep.StepServices,
        contextQuestionList: stepServices,
        previousContext: optionsBaseContext,
        questions,
        isVendeur,
    });

    const lastServiceQestionContext = QuestionContextHelper.getBaseQuestionsContext(stepServices?.questions);
    QuestionPrepare.updateGeneralStepsQuestions({
        step: QuestionStep.StepSynthese,
        contextQuestionList: stepSynthese,
        questions,
        isVendeur,
        previousContext: lastServiceQestionContext || optionsBaseContext
    });
    return { ...quoteState, modified: true, stepServices, stepSynthese };
};


export const getStepServiceBaseContext = (mapState: MapContextState, quoteState: QuoteState) => {
    const { stepHabitation } = quoteState;
    const { rooms = [] } = mapState;
    const roomsSol = rooms.filter(x => x.type === RoomType.Sol);
    const roomsWall = rooms.filter(x => x.type === RoomType.Wall);

    const stepHabitationContext = QuestionContextHelper.getBaseQuestionsContext(stepHabitation?.questions);
    const optionsBaseContext = QuestionContextHelper.mergeContext(stepHabitationContext, {
        evaluations: {
            [QuestionContextHelper.ragreageObligatoreKey]: QuestionContextHelper.isRagreageObligatoireExist(rooms) ? QuestionContextHelper.ragreageObligatoreValue : '',
        },
        rooms: roomsSol.map(room => QuestionContextUtils.getLastStepContext(room, QuestionStep.StepFinitions)),
        walls: roomsWall.map(room => QuestionContextUtils.getLastStepContext(room, QuestionStep.StepFinitions))
    });
    return optionsBaseContext;
}

export const getStepSyntheseBaseContext = (mapState: MapContextState, quoteState: QuoteState) => {
    const { stepServices } = quoteState;
    const optionsBaseContext = getStepServiceBaseContext(mapState, quoteState);
    const lastServiceQestionContext = QuestionContextHelper.getBaseQuestionsContext(stepServices?.questions);
    return lastServiceQestionContext || optionsBaseContext;
}