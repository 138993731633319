

//*todo : merge with frMessages in fr.ts
export const frFloorPlanMessages = {

    Locale: 'fr-FR',

    COMMON_CM_UNIT: 'cm',

    AddRoomDialogTitle: 'Pièces',
    AddOpeningWarningDialogTitle: 'Ouvertures',
    AddRoomItemDialogTitle: 'Autres éléments',
    LaizeDirectionTitle_rouleau_vertical: 'Sens de pose : vertical',
    LaizeDirectionTitle_rouleau_horizontal: 'Sens de pose : horizontal',
    LaizeDirectionTitle_lamePleine_vertical: 'Disposition des lames : vertical',
    LaizeDirectionTitle_lamePleine_horizontal: 'Disposition des lames : horizontal',
    LaizeDirectionTitle_lamePleine_classique: 'Disposition des lames : optimisation des lames',
    LaizeDirectionTitle_lamePleine_non: 'Disposition des lames : optimisation des lames',
    LegendInfoText_Sol: 'Les côtes représentent les dimensions intérieures des pièces.',
    LegendInfoText_Wall: 'Les côtes représentent les dimensions intérieures des murs.',
    EditCoteTitle: 'Édition de cote',

    LegendInfoText: 'Les cotes représentent les dimensions intérieures des pièces.',

    //* OPENINGS
    Opening_Door: 'Porte',
    Opening_ArmoredDoor: 'Porte blindée',
    Opening_FrenchDoor: 'Porte fenêtre',
    Opening_FrontDoor: "Porte d'entrée",
    Opening_PatioDoor: 'Baie vitrée',
    Opening_opening: 'Passage ouvert',

    //* ROOM ITEMS - SOL
    RoomItem_ShowerBathtub: 'Douche / Baignoire',
    RoomItem_FixedFurniture: 'Meuble non déplaçable',
    RoomItem_Cupboard: 'Placard',
    RoomItem_KitchenFurniture: 'Meuble de cuisine',
    RoomItem_Trap: 'Trappe',
    RoomItem_Fireplace: 'Cheminée',
    RoomItem_Opening: 'Ouverture',
    RoomItem_Stairs: 'Marche',
    RoomItem_Divider: 'Cloison',
    RoomItem_HeavyElement: 'Élément lourd (jaccuzi, pot fleur...)',
    RoomItem_SewerPlate: 'Regard, plaque égout',

    //* ROOM ITEMS - WALL
    RoomItem_Door: 'Porte',
    RoomItem_Window: 'Fenêtre',
    RoomItem_UnlaidArea: 'Zone non posée',

    RoomItemDialog_Stairs_Warning: 'Attention : Cet élément représente une seule marche.',
    RoomItemDialog_DimensionsWidthPropertyMissingError: "Veuillez saisir la largeur de l'élément.",
    RoomItemDialog_DimensionsWidthPropertyMissingErrorLimits: 'Veuillez saisir la largeur comprise entre ({min}) et ({max}) cm',

    RoomItemDialog_DimensionsLengthPropertyMissingError: "Veuillez saisir la longueur de l'élément.",
    RoomItemDialog_DimensionsLengthPropertyMissingErrorLimits:
        'Veuillez saisir la longueur comprise entre ({min}) et ({max}) cm',
    RoomItemDialog_PutFlooringPropertyLabel: 'Faut-il y poser le revêtement ?',
    RoomItemDialog_PutPlinthesPropertyLabel: 'Faut-il poser des plinthes également ?',

    EditCoteDialog_DimensionPropertyLabel: 'Dimension (cm)',
    EditCoteDialog_DimensionPropertyDescription: 'la cote doit être comprise entre ({mini}) et ({maxi}) cm',

    RoomItemDialog_RoomTypeList_ChambreDressing: 'Chambre / Dressing',
    RoomItemDialog_RoomTypeList_Bureau: 'Bureau',
    RoomItemDialog_RoomTypeList_Salon: 'Salon',
    RoomItemDialog_RoomTypeList_SalleManger: 'Salle à manger',
    RoomItemDialog_RoomTypeList_Cuisine: 'Cuisine',
    RoomItemDialog_RoomTypeList_WC: 'WC',
    RoomItemDialog_RoomTypeList_SalleEau: "Salle d'eau ou salle de bain",
    RoomItemDialog_RoomTypeList_Buanderie: 'Buanderie',
    RoomItemDialog_RoomTypeList_Veranda: 'Veranda',
    RoomItemDialog_RoomTypeList_HallEntree: 'Hall / entrée',
    RoomItemDialog_RoomTypeList_Couloir: 'Couloir',
    RoomItemDialog_RoomTypeList_Garage: 'Garage',
    RoomItemDialog_RoomTypeList_TerasseBalconCouvert: 'Terrasse ou balcon sous abri',
    RoomItemDialog_RoomTypeList_TerasseBalconNonCouvert: 'Terrasse ou balcon non couverte',
    RoomItemDialog_RoomTypeList_BordPiscinePlat: 'Bord de piscine plat',
    RoomItemDialog_RoomTypeList_BordPiscineIncline: 'Bord de piscine incliné',
    RoomItemDialog_RoomTypeList_Jardin: 'Jardin',
    RoomItemDialog_RoomTypeList_LocalProIndividuel: 'Local professionnel individuel',
    RoomItemDialog_RoomTypeList_PartieCommuneCoPro: "Parties communes d'une co-propriété",
    RoomItemDialog_RoomTypeList_LocalProCollectif: 'Local professionnel collectif',
    RoomItemDialog_RoomTypeList_ChambreHotel: "Chambre d'hotel",
    RoomItemDialog_RoomTypeList_Boutique: 'Boutique',
    RoomItemDialog_RoomTypeList_LieuTresFrequente: 'Lieu très fréquenté',
    RoomItemDialog_RoomTypeList_Industrie: 'Industrie',
    RoomItemDialog_RoomTypeList_Evenementiel: 'Évènementiel',
    RoomItemDialog_RoomTypeList_Ascenseur: 'Ascenseur',
    RoomItemDialog_RoomTypeList_AutresPartiesCommunes: 'Autres parties communes',
    RoomItemDialog_RoomTypeList_Cour: 'Cour',
    RoomItemDialog_RoomTypeList_ToitTerrasseAvecEtanchéite: 'Toit terrasse avec étanchéité',
    RoomItemDialog_RoomTypeList_Paves: 'Pavés',
    RoomItemDialog_RoomTypeList_GraviersEnrobes: 'Graviers / enrobés',
    RoomItemDialog_RoomTypeList_StructureBoisMetal: 'Structure (Bois, métal)',

    RoomItemDialog_RoomFloorList_RDC: 'RDC',
    RoomItemDialog_RoomFloorList_Etage1a3: '1er au 3ème étage',
    RoomItemDialog_RoomFloorList_4etPlus: '4ème étage et plus',
    RoomItemDialog_RoomFloorList_Floor: 'étage',

    RoomItemDialog_RoomCurrentFlooringList_Beton: 'Béton (Fondation dalle brute)',
    RoomItemDialog_RoomCurrentFlooringList_Ciment: 'Ciment (chape ou ragréage)',
    RoomItemDialog_RoomCurrentFlooringList_PlancherBois: 'Plancher en bois',
    RoomItemDialog_RoomCurrentFlooringList_BétonPeintCire: 'Béton peint ou ciré',
    RoomItemDialog_RoomCurrentFlooringList_ChapeSeche: 'Chape sèche (plaque de rénovation)',
    RoomItemDialog_RoomCurrentFlooringList_Terre: 'Terre',
    RoomItemDialog_RoomCurrentFlooringList_ParquetColle: 'Parquet collé',
    RoomItemDialog_RoomCurrentFlooringList_ParquetStratifieNonColle: 'Parquet ou stratifié non collé',
    RoomItemDialog_RoomCurrentFlooringList_Carrelage: 'Carrelage',
    RoomItemDialog_RoomCurrentFlooringList_Moquette: 'Moquette',
    RoomItemDialog_RoomCurrentFlooringList_RevetementPlastique: 'Plastique (vinyle, lino, dalle, gazon)',
    RoomItemDialog_RoomCurrentFlooringList_TerasseBoisComposite: 'Terrasse bois ou composite',

    RoomItemDialog_RoomCurrentFlooringList_Peinture: 'Peinture',
    RoomItemDialog_RoomCurrentFlooringList_PapierPeint: 'Papier peint',
    RoomItemDialog_RoomCurrentFlooringList_Crepis: 'Crepis',
    RoomItemDialog_RoomCurrentFlooringList_Lambris: 'Lambris',
    RoomItemDialog_RoomCurrentFlooringList_MoquetteMurale: 'Moquette murale',
    RoomItemDialog_RoomCurrentFlooringList_VinyleMural: 'Vinyle mural',

    RoomItemDialog_RoomCurrentFlooringList_PlacoPlatreCiment: 'Placo / Platre / Ciment',
    RoomItemDialog_RoomCurrentFlooringList_Beton_Wall: 'Beton',
    RoomItemDialog_RoomCurrentFlooringList_Bois: 'Bois',
    RoomItemDialog_RoomCurrentFlooringList_Pierre: 'Pierre',
    RoomItemDialog_RoomCurrentFlooringList_Parpaing: 'Parpaing',

    RoomItemDialog_RoomHeatingTypeList_PasDeChauffage: "Je n'ai pas de chauffage au sol",
    RoomItemDialog_RoomHeatingTypeList_BasseTemperatureEau: 'Basse T° à eau (<28°)',
    RoomItemDialog_RoomHeatingTypeList_BasseTemperatureAccumulation: 'Basse T° à accumulation (<28°)',
    RoomItemDialog_RoomHeatingTypeList_HauteTemperature: 'Haute température (>28°)',
    RoomItemDialog_RoomHeatingTypeList_ElectriqueRayonnant: 'Électrique rayonnant',
    RoomItemDialog_RoomHeatingTypeList_Reversible: 'Réversible',
    RoomItemDialog_RoomHeatingTypeList_SystemeRapporte: 'Système rapporté',
    RoomItemDialog_RoomHeatingTypeList_UnknownType: 'Je ne connais pas le type de chauffage au sol',

    RoomItemDialog_RoomWallTypeList_Mur: 'Mur',
    RoomItemDialog_RoomWallTypeList_Credence: 'Crédence',
    RoomItemDialog_RoomWallTypeList_TablierBaignoire: 'Tablier baignoire',

    LaizeLines_Title: 'CONSO PRODUIT',
    LaizeDirectionDialog_Description: "Le sens de pose ne se choisit pas au hasard, il impacte la quantité de produit : on peut opter pour une pose en fonction de l'entrée de la lumière dans la pièce, du sens de la marche ou encore en fonction du nombre de raccords. Si vous avez un projet multi-pièces le sens de pose sera identique. Réfléchissez-y, nous validerons avec vous !",
    LaizeView_Title: 'CONSO PRODUIT',
    LaizeDirectionView_length: 'Mètres linéaires',
    LaizeDirectionView_length_LamePleine_Horizontal: "Lames entières à l'horizontal",
    LaizeDirectionView_length_LamePleine_Vertical: 'Lames entières à la verticale',
    LaizeDirectionView_length_LamePleine_Classique: 'Optimisation des lames',

    AmenagementDialog_Title: "Etes-vous sûr de ne pas avoir d'aménagement ?",
    AmenagementDialog_Subtitle: `Une ouverture peut être une porte, porte-fenêtre, baie vitrée ou même un passage ouvert vers une autre pièce.\nUn aménagement peut être un placard, une douche, des meubles de cuisine ... soit des d'éléments que nous déduisons de la surface totale de la pièce.\nPositionnez-les pour avoir l'estimation la plus juste possible !`,
    AmenagementDialog_Yes: 'Finalisez votre projet',
    AmenagementDialog_No: 'Revenir au plan',

    OpeningWarning_RoomInfoIncomplete_Dialog_Title: 'Il manque quelque chose',
    OpeningWarning_RoomInfoIncomplete_Dialog_Subtitle: `Merci de compléter la section "Ma pièce" pour ajouter un aménagement sur cette pièce.`,
    OpeningWarning_RoomInfoIncomplete_Dialog_CTA: 'Compléter "Ma pièce"',

    OpeningWarning_SelectRoom_Dialog_Title: "Déplacement de l'ouverture",
    OpeningWarning_SelectRoom_Dialog_Subtitle: "Attacher l'ouverture à une pièce ?",
    OpeningWarning_SelectRoom_Dialog_Validate: "Choisir cette pièce",

    MoveAmenagementWarning_Dialog_Title: 'Déplacement impossible',
    MoveAmenagementWarning_Dialog_Title_CTA: 'Annuler le déplacement',
    MoveOpeningWarning_Dialog_Subtitle: `Le type d'ouverture "{type}" n'est pas compatible sur les pièces {localisation}.`,
    MoveRoomItemWarning_Dialog_Subtitle: `Le type d'aménagement "{roomItemType}" n'est pas compatible sur les pièces {unit} - {localisation} - {roomType}.`,

    Contournement_Toast_Success: 'Contournements enregistrés !',

    EstimationViewRollOver_Title_Complete: 'Finalisez votre projet',
    EstimationViewRollOver_Title_ToDo: 'Finaliser mon projet',
    EstimationViewRollOver_Title_NoRoom_Sol: 'Ajouter une pièce',
    EstimationViewRollOver_Title_NoRoom_Wall: 'Ajouter un mur',
    EstimationViewRollOver_Title_SeeQuote: 'Voir mon devis',
};

export type FloorPlanStringKey = keyof typeof frFloorPlanMessages;
