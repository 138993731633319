import Enumerable from 'linq';

import { Constants } from '../Constants';
import { Logger } from '../Errors/Logger';
import { wrapError } from '../Errors/PolErrorHandler';
import { I18n } from '../Locales/I18nService';
import { ProduitDto, ProduitListResponse, ProduitResponse } from '../Models/Questions/ProduitDto';
import {
    CommentPropertiesInputType,
    ConditionDto,
    IncompatibilityDto,
    OptionDto,
    QuestionContextType,
    QuestionItem,
    QuestionStep,
    QuestionType,
} from '../Models/Questions/QuestionDto';
import { LocalitiesSearchResultDto, LocalityDto } from '../Models/TravelExpenses/TravelExpenseDto';
import { ProductType } from '../Plugins/FloorPlan/Components/Laizes/Laize';
import { QuestionApi } from '../Services/Api/QuestionApi';
import { Toast } from '../Web/Services/ToastService';
import { ConditionHelper } from './ConditionHelper';
import { ConfigurationUtils } from './ConfigurationUtils';
import { ObjectUtils } from './ObjectUtils';
import { ProductHelper } from './ProductHelper';
import { QuestionContext, QuestionContextHelper } from './QuestionContextHelper';

//#region  //* Methods Parameters

export interface InitilizeQuestionsParameters {
    questions: Array<QuestionItem>;
    initialContext?: QuestionContext;
    produitPrincipal?: ProduitDto;
    withLog?: boolean;
}

export interface SetValueOnQuestionParameter {
    readonly value?: string | OptionDto | Array<OptionDto> | LocalityDto;
    readonly valueExludeResponse?: boolean;
    readonly removeValue?: boolean;
    readonly checkValue?: boolean;
    readonly scrollToNextQuestion?: boolean;
}

export type SetValueOnQuestionResult = { updatedQuestion: QuestionItem; updatedContext: QuestionContext; }
export type InvalidValueOnQuestionResult = { updatedQuestion: QuestionItem; updatedContext: QuestionContext; }
export type InvalidValueOnQuestionParameters = { question: QuestionItem; }
export type UpdateQuestionAfterLoadProduitsParameters = { question: QuestionItem; logs?: any }
export type FindFirstIncompatibilityResult = { findIncompatibility?: IncompatibilityDto; questionWithIncompatibility?: QuestionItem; }

export interface IsQuestionVisibleParameters {
    readonly question: QuestionItem;
    readonly context: QuestionContext;
    readonly produitPrincipalCompleteCode: string | undefined;
    readonly log?: string;
    readonly withLog?: boolean;
}


export interface IsConditionVisibleParameters {
    readonly condition: ConditionDto;
    readonly context: QuestionContext;
    readonly produitPrincipalCompleteCode: string | undefined;
    readonly familleProduitCodeList: Array<string>;
    readonly log?: string;
    readonly withLog?: boolean;
}

export interface UpdateQuestionVisibilityAndContextParams {
    readonly question: QuestionItem;
    readonly previousQuestion?: QuestionItem;
    readonly produitPrincipalCompleteCode?: string;
    log?: string;
}

export interface NextQuestionToShowParameters {
    readonly fromQuestion: QuestionItem;
    readonly questions?: Array<QuestionItem>;

    //* La sous-famille en cours est celle du produit sélectionné à la première question produit affichée du formulaire
    readonly produitPrincipalCompleteCode?: string;
}


export interface LoadProduitsParameters {
    readonly question: QuestionItem;
    readonly search?: string;
    readonly context: QuestionContext;
    readonly updateQuestion: (params: UpdateQuestionAfterLoadProduitsParameters) => void;
    readonly showNextQuestionIfNeeded?: (fromQuestion: QuestionItem) => void;
    readonly handleIncompatibilityOnDefaultProduit?: (
        questionWithIncompatibility: QuestionItem,
        findIncompatibility: IncompatibilityDto
    ) => void;
    readonly versionId: string;
}

export interface LoadLocalityParameters {
    readonly question: QuestionItem;
    readonly zipCode: string;
    readonly updateQuestion: (question: QuestionItem, value?: LocalitiesSearchResultDto) => void;
    versionId: string;
}

export interface CheckStepCompletedParameters {
    readonly isVendeur?: boolean;
    readonly questions?: Array<QuestionItem>;
    readonly produitPrincipal?: ProduitDto;
    readonly withLog?: boolean;
}

export interface IsStepCompletedResult {
    readonly isEmpty: boolean; //pas de question visible (question respisration exclus)
    readonly isStepCompleted: boolean;
}

//#endregion
export class QuestionsHelper {
    public static DATE_VALIDATOR = new RegExp(/^((0[1-9])|([1-9])|(1[0-2]))\/[0-9]{4}$/); //* MM/YYYY

    public static updateQuestions(questions: QuestionItem[], questionToUpdate: QuestionItem): QuestionItem[] {
        return questions.map((question: QuestionItem) => question.id === questionToUpdate.id ? questionToUpdate : question);
    }

    public static getPreviousVisibleQuestion = (
        fromQuestion: QuestionItem,
        questions: Array<QuestionItem> = []
    ): QuestionItem | undefined => {
        if (questions.length > 0) {
            const previousQuestion: QuestionItem | undefined = Enumerable.from(questions).lastOrDefault(
                (q: QuestionItem) => q.index < fromQuestion.index && !!q.showQuestion
            );
            return previousQuestion;
        }
        return undefined;
    };

    public static getNextVisibleQuestion = (
        fromQuestion: QuestionItem,
        questions: Array<QuestionItem> = []
    ): QuestionItem | undefined => {
        if (questions.length > 0) {
            const nextQuestion: QuestionItem | undefined = Enumerable.from(questions).firstOrDefault(
                (q: QuestionItem) => q.index > fromQuestion.index && !!q.showQuestion
            );
            return nextQuestion;
        }
        return undefined;
    };

    public static getLastShownQuestion(questions: Array<QuestionItem> = []) {
        const lastShownQuestionIndex: number = Enumerable.from(questions).lastIndexOf((q: QuestionItem) =>
            Boolean(q.showQuestion)
        );
        const lastShownQuestion = questions[lastShownQuestionIndex];
        return { lastShownQuestion, lastShownQuestionIndex };
    }

    public static getLastQuestionContext(questions: QuestionItem[] = []) {
        return questions[questions.length - 1];
    }

    public static validateCommentAndRespirationQuestionIfNeeded = (
        questions: Array<QuestionItem>,
        validCommentType: boolean = true
    ) => {
        const { lastShownQuestion } = QuestionsHelper.getLastShownQuestion(questions);
        questions.forEach((question: QuestionItem) => {
            if (
                question.index <= lastShownQuestion?.index &&
                (question.type === QuestionType.Respiration ||
                    (validCommentType && question.type === QuestionType.Comment))
            ) {
                question.isValid = true;
            }
        });
    };

    public static hideQuestionsAfter(lastVisibleQuestion: QuestionItem, questions: Array<QuestionItem>) {
        questions.forEach((question: QuestionItem) => {
            if (question.index > lastVisibleQuestion.index) {
                question.showQuestion = false;
            }
        });
    }

    public static findQuestionProduitWhichRefersTheQuestion(
        questionProduit: QuestionItem,
        questions: Array<QuestionItem> = []
    ): QuestionItem | undefined {
        return questions.length > 0
            ? Enumerable.from(questions).firstOrDefault(
                (q) =>
                    Boolean(q.showQuestion) &&
                    q.type === QuestionType.Produit &&
                    Boolean(
                        questionProduit.produits.find(
                            (x) => x.suggestion.reference_question.id === questionProduit.id
                        )
                    )
            )
            : undefined;
    }

    public static firstQuestionWithNoResponse = (questions: Array<QuestionItem> = []) => {
        if (questions.length) {
            return (
                Enumerable.from(questions).firstOrDefault((q: QuestionItem) => Boolean(q.showQuestion) && !q.isValid) ||
                questions[0]
            );
        }
        return undefined;
    };

    //#region PRODUITS

    public static async loadProduits(loadProduitsParameter: LoadProduitsParameters) {
        const { question, search, updateQuestion } = loadProduitsParameter;
        try {
            question.produitSearch = search ?? question.produitSearch;
            question.loadingProduits = true;
            question.produitsLoaded = false;
            updateQuestion({ question, logs: 'before fetchProduits' });

            const { isSuccess, produitList } = await this.fetchProduits(loadProduitsParameter);

            question.produitsLoaded = true;
            question.loadingProduits = false;

            if (isSuccess) {
                const produits: Array<ProduitDto> = produitList!;
                question.produits = produits;
                question.needLoadProduits = false;

                // autoselect readonly product.
                if (question.produitReadonlyReference) {
                    this.setValueOnQuestion(question, { value: question.produitReadonlyReference });
                }
                updateQuestion({ question, logs: 'after success fetchProduits' });
            }
        } catch (error) {
            console.log('loadProduits error', { error });
            question.loadingProduits = false;
            question.produitsLoaded = true;
            question.produits = [];
            updateQuestion({ question, logs: 'after failed fetchProduits' });
        }
    }

    private static fetchProduits = async (loadProduitsParameter: LoadProduitsParameters) => {
        const { context, question, search, updateQuestion, versionId } = loadProduitsParameter;

        const stringContext: string = JSON.stringify(context ?? {});

        if (question.produitReadonlyReference) {
            try {
                const produitResponse: ProduitResponse = await QuestionApi.getProduit(
                    question.produitReadonlyReference,
                    { data_context: { context: stringContext } }
                );

                return {
                    isSuccess: produitResponse.is_success,
                    produitList: produitResponse.data ? [{ ...produitResponse.data, is_default: true }] : undefined,
                };
            } catch (error) {
                Toast.showError({ content: I18n.get('QuoteStepViewModel_ReadonlyProduct_Error') });

                //Reset the question to allow the user to search a product...
                question.produitReadonlyReference = undefined;
                updateQuestion({ question });
            }
        }

        const withSearch = question.produit_properties.catalogue_search_allowed ? search : '';
        const produitListResponse: ProduitListResponse = await QuestionApi.getProduits(question.id, {
            versionId,
            search: withSearch,
            data_context: { context: stringContext },
            count: withSearch ? Constants.QUESTION_PRODUIT_COUNT : Constants.QUESTION_SUGGESTION_PRODUIT_COUNT,
        });

        return { isSuccess: produitListResponse.is_success, produitList: produitListResponse.data };
    };

    //#endregion

    //#region ZIPCODE

    public static loadLocality({ question, updateQuestion, zipCode, versionId }: LoadLocalityParameters) {
        class UnAllowedError extends Error { }
        wrapError(async () => {
            question.zipCodeValueLoading = true;
            updateQuestion(question, { ...question.zipCodeValue, zipCode });

            try {
                if (zipCode) {
                    const travelExpense = await QuestionApi.getLocalities(question.id, { zipCode, versionId });
                    question.zipCodeValueLoading = false;

                    if ('is_success' in travelExpense) {
                        updateQuestion(question, { ...travelExpense.data, zipCode });

                        if (travelExpense.data.unallowed) {
                            throw new UnAllowedError(`The zip code ${zipCode} is not allowed`);
                        }

                        if (!travelExpense.is_success || !travelExpense.data || !travelExpense.data.found) {
                            throw new Error('No travel expense found');
                        }
                    } else {
                        updateQuestion(question, { ...question.zipCodeData });
                    }
                } else {
                    question.zipCodeValueLoading = false;
                    updateQuestion(question, { localities: [], zipCode: '' });
                }
            } catch (e) {
                question.zipCodeValueLoading = false;
                updateQuestion(question, { zipCode });
                if (e instanceof UnAllowedError) {
                    Toast.showError({
                        title: I18n.get('COMMON_SORRY_TITLE'),
                        content: I18n.get('QuoteStepViewModel_TravelExpense_Unallowed_Error'),
                    });
                } else {
                    Toast.showError({ content: I18n.get('QuoteStepViewModel_TravelExpense_Error') });
                }
            }
        });
    }

    //#endregion

    //#region INITIALIZE / PREPARE QUESTIONS

    public static initializeQuestions = (initilizeQuestionsParameters: InitilizeQuestionsParameters) => {
        let { questions, initialContext, produitPrincipal, withLog } = initilizeQuestionsParameters;

        const produitPrincipalCompleteCode = ProductHelper.getCompleteCode(produitPrincipal);

        //* INVALID QUESTION PRODUIT IF PRODUIT PRINCIPAL HAS CHANGED
        questions.filter((q) => Boolean(q.produitValue)).forEach((q) => {
            if (q.produitPrincipalCode !== produitPrincipal?.code) {
                withLog && Logger.log('update >> QuestionsHelper.invalidValueOnQuestion', { question: q, produitPrincipal });
                QuestionsHelper.invalidValueOnQuestion({ question: q });
            }
        });

        let continueProcess: boolean = true;
        let lastShownQuestion: QuestionItem | undefined = undefined;
        questions.forEach((question: QuestionItem) => {
            if (continueProcess) {
                const previousVisibleQuestion = QuestionsHelper.getPreviousVisibleQuestion(question, questions);
                const valueContext = this.getValidatedContext(question);

                //* FIRST QUESTION : no context heritage
                if (!previousVisibleQuestion) {
                    question.context = QuestionContextHelper.mergeContext(initialContext, valueContext);
                    QuestionsHelper.updateQuestionVisibilityAndContext({ question, produitPrincipalCompleteCode });
                } else {
                    const previousQuestion: QuestionItem = ObjectUtils.clone(previousVisibleQuestion);
                    //* assign empty context + previous context
                    question.context = QuestionContextHelper.mergeContext(previousQuestion.context, valueContext);
                    QuestionsHelper.updateQuestionVisibilityAndContext({
                        question,
                        previousQuestion,
                        produitPrincipalCompleteCode,
                    });
                }

                if (question.type === QuestionType.Respiration) {
                    QuestionsHelper.setValueOnQuestion(question, {});
                }

                //* continueProcess until
                //* current question has no response et isVisible with own context
                if (question.showQuestion && !question.isValid) {
                    question.focusQuestion = true;
                    lastShownQuestion = question;
                    continueProcess = false;
                }
            }
        });

        //* Si une modification de paramétrage fait qu'une question,
        //* au milieu du questionnaire n'est pas répondu
        //* alors je dois pouvoir naviguer jusqu'à cette question
        //* mais les questions suivantes ne doivent pas être affichées.
        //* Si je réponds à la question manquante,
        //* ce qui était déjà répondu ensuite doit être appliqué

        //const { lastShownQuestion } = this.getLastShownQuestion(questions);
        if (lastShownQuestion) {
            QuestionsHelper.hideQuestionsAfter(lastShownQuestion, questions);
        }
    };

    public static invalidValueOnQuestion({ question }: InvalidValueOnQuestionParameters): InvalidValueOnQuestionResult {
        const context: QuestionContext = question.context;
        if (question.type === QuestionType.Number) {
            question.numberValue = undefined;
            question.errorInvalidNumberValue = false;
            question.isValid = false;
            context[QuestionContextHelper.toQuestionValue(question.id)] = undefined;
        }
        if (question.type === QuestionType.SingleChoice) {
            question.optionValue = undefined;
            context[QuestionContextHelper.toQuestionOption(question.id)] = undefined;
            context[QuestionContextHelper.toQuestionValue(question.id)] = undefined;
            question.isValid = false;
        }
        if (question.type === QuestionType.MultipleChoices) {
            question.optionValues = undefined;
            context[QuestionContextHelper.toQuestionOption(question.id)] = undefined;
            context[QuestionContextHelper.toQuestionValue(question.id)] = undefined;
            question.isValid = false;
        }
        if (question.type === QuestionType.Comment) {
            question.commentValue = undefined;
            context[QuestionContextHelper.toQuestionCommentvalue(question.id)] = undefined;
            question.isValid = false; //* no check on comment
        }

        if (question.type === QuestionType.Produit) {
            const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            delete context[QuestionContextHelper.toQuestionProduitValue(question.id)];
            context[questionProduitKey] = undefined; //! delete key
            question.produitValue = undefined;

            question.produits = [];
            question.produitsLoaded = false;
            question.needLoadProduits = true;
            question.produitSearch = undefined;
            question.produitPrincipalCode = undefined;

            question.isValid = question.produit_properties.is_required && Boolean(question.produitValue);
        }
        question.context = context;

        const invalidValueOnQuestionResult: InvalidValueOnQuestionResult = {
            updatedQuestion: question,
            updatedContext: context,
        };
        return invalidValueOnQuestionResult;
    }

    //#endregion

    public static hasInfobules = (question: QuestionItem) => {
        return (question.infobulle_list || []).some((infobulle) =>
            QuestionsHelper.isVisible(question, infobulle.visibility_condition)
        );
    };

    public static isVisible = (
        question: QuestionItem,
        condition: ConditionDto,
        withLog?: boolean,
        log?: Record<string, any>
    ): boolean => {
        const resultIsVisible: boolean = ConditionHelper.isConditionEvaluateToTrue({
            question,
            condition,
            rawContext: question.context,
            log,
            withLog,
        });
        return resultIsVisible;
    };

    public static isQuestionVisible(questionVisibleParameters: IsQuestionVisibleParameters): boolean {
        const { question, context, produitPrincipalCompleteCode, withLog, log } = questionVisibleParameters;
        return QuestionsHelper.isConditionVisible({
            context,
            condition: question.visibility_condition,
            familleProduitCodeList: question.famille_produit_code_list,
            produitPrincipalCompleteCode,
            withLog,
            log,
        });
    }

    public static isConditionVisible = (conditionVisibleParameters: IsConditionVisibleParameters) => {
        const {
            condition,
            familleProduitCodeList = [],
            context,
            produitPrincipalCompleteCode,
            withLog,
            log,
        } = conditionVisibleParameters;

        const isQuestionSousFamillesIncludesPrincipalPrduit = (): boolean => {
            if (produitPrincipalCompleteCode && familleProduitCodeList.length > 0) {
                //* on vérifie
                //* si le produit principal est de la bonne sous-famille que la question
                //* si oui -> on affiche la question
                const isFamilleCodeListIncludesPrincipalCompleCode =
                    familleProduitCodeList.includes(produitPrincipalCompleteCode);
                //console.log(`${log} : isFamilleCodeListIncludesPrincipalCompleCode 0`, ObjectUtils.clone({ question, context, produitPrincipalCompleteCode, isFamilleCodeListIncludesPrincipalCompleCode }));
                return isFamilleCodeListIncludesPrincipalCompleCode;
            }
            //* si pas de prduit principal
            //* alors pas de restriction d'affichage
            return true;
        };

        //* can be QUESTION or PRICE
        let itemIsVisible: boolean = true;

        if (condition) {
            const itemIsVisibleBySousFamille: boolean = isQuestionSousFamillesIncludesPrincipalPrduit();
            const itemIsVisibleByCondition: boolean = ConditionHelper.isConditionEvaluateToTrue({
                condition,
                rawContext: context,
                withLog,
            });
            itemIsVisible = itemIsVisibleBySousFamille && itemIsVisibleByCondition;
            withLog &&
                console.log(`${log} : isConditionVisible 1 + V-Condition`, {
                    itemIsVisibleBySousFamille,
                    itemIsVisibleByCondition,
                    condition,
                    itemIsVisible,
                    context,
                    produitPrincipalCompleteCode,
                });
        } else {
            const itemIsVisibleBySousFamille: boolean = isQuestionSousFamillesIncludesPrincipalPrduit();
            itemIsVisible = itemIsVisibleBySousFamille;
            withLog &&
                console.log(
                    `${log} : isConditionVisible 2 - V-Condition`,
                    ObjectUtils.clone({
                        itemIsVisibleBySousFamille,
                        condition,
                        itemIsVisible,
                        context,
                        produitPrincipalCompleteCode,
                    })
                );
        }
        return itemIsVisible;
    };

    public static setValueOnQuestion(
        question: QuestionItem,
        valueParameter: SetValueOnQuestionParameter,
        produitPrincipal?: ProduitDto
    ): SetValueOnQuestionResult {
        const { value, valueExludeResponse, removeValue, checkValue } = valueParameter;

        let context: QuestionContext = ObjectUtils.clone(question.context || {});

        if (question.type === QuestionType.Number) {
            const numberValue = value as string;

            const { minimum, maximum } = question.number_properties;
            const valueAsNumber: number = parseInt(numberValue);
            const isValueAsNumberValid = valueAsNumber >= minimum && valueAsNumber <= maximum;
            question.numberValue = numberValue;

            if (!isValueAsNumberValid) {
                //* question answer is not valid, show error
                question.errorInvalidNumberValue = true;
                question.isValid = false;
            } else {
                question.errorInvalidNumberValue = false;
                context[QuestionContextHelper.toQuestionValue(question.id)] = numberValue;
                question.isValid = true;
            }
        }
        if (question.type === QuestionType.SingleChoice) {
            const optionValue = value as OptionDto;
            question.optionValue = optionValue;
            context[QuestionContextHelper.toQuestionOption(question.id)] = QuestionContextHelper.toOptionId(
                optionValue.id
            );
            context[QuestionContextHelper.toQuestionValue(question.id)] = optionValue.value;
            question.isValid = question.optionValue !== undefined;
        }
        if (question.type === QuestionType.MultipleChoices) {
            const optionValues = value as Array<OptionDto>;
            question.optionValues = optionValues;
            context[QuestionContextHelper.toQuestionOption(question.id)] = optionValues.map((x) =>
                QuestionContextHelper.toOptionId(x.id)
            );
            context[QuestionContextHelper.toQuestionValue(question.id)] = optionValues.values;
            question.isValid = !optionValues.length ? false : question.isValid;
            if (valueParameter.checkValue) {
                question.isValid = question.optionValues !== undefined;
            }
        }
        if (question.type === QuestionType.Comment) {
            const commentValue = value as string;
            question.commentValue = commentValue;

            if (question.comment_properties.input_type === CommentPropertiesInputType.DateMMAAAA) {
                question.isValid = this.DATE_VALIDATOR.test(commentValue);
            } else if (valueParameter.checkValue) {
                question.isValid = true
            }

            if (valueParameter.checkValue) {
                context[QuestionContextHelper.toQuestionCommentvalue(question.id)] = commentValue;
            }
        }
        if (question.type === QuestionType.Produit) {
            const produitCode = value as string;

            const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            question.excludeResponseSelected = valueExludeResponse;
            if (!produitCode) {
                //* si click sur la croix de l'élément selectioné ou la réponse d'éxclusion
                if (removeValue) {
                    delete context[QuestionContextHelper.toQuestionProduitValue(question.id)];
                    context[questionProduitKey] = undefined; //! delete key
                    question.produitValue = undefined;
                    question.produitPrincipalCode = undefined;
                }
                //* si click sur la réponse d'éxclusion
                if (checkValue) {
                    question.isValid = Boolean(question.produitValue) || question.excludeResponseSelected;
                }
            } else {
                const produit = question.produits.find((p: ProduitDto) => p.code === produitCode);
                if (produit) {
                    question.produitValue = produit;
                    question.produitPrincipalCode = produitPrincipal?.code;
                    context[questionProduitKey] = QuestionContextHelper.toProduitValue(produit);
                    context[QuestionContextHelper.toQuestionProduitValue(question.id)] = produit.code;
                }
                if (checkValue) {
                    question.isValid = question.produitValue !== undefined;
                }
            }
        }
        if (question.type === QuestionType.Respiration) {
            question.isValid = true;
        }
        if (question.type === QuestionType.ZipCode) {
            const localityDto = value as LocalityDto;
            question.zipCodeValue = localityDto;
            question.isValid = Boolean(localityDto);

            if (question.isValid) {
                const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
                context[questionProduitKey] = QuestionContextHelper.toZipCodeValue(localityDto);
                context = QuestionContextHelper.withRegionParisienne(localityDto, context);
            }
        }

        const contextWithEvaluation: QuestionContext = ConfigurationUtils.evalQuestionEvalutations(question, context);
        question.context = contextWithEvaluation;

        const setValueOnQuestionResult: SetValueOnQuestionResult = {
            updatedQuestion: question,
            updatedContext: context,
        };
        return setValueOnQuestionResult;
    }

    public static getValidatedContext(question: QuestionItem): QuestionContext {
        let context: QuestionContext = ObjectUtils.clone({});

        if (question.type === QuestionType.Number) {
            context[QuestionContextHelper.toQuestionValue(question.id)] = question.numberValue;
        }
        if (question.type === QuestionType.SingleChoice && question.optionValue) {
            const optionValue = question.optionValue;
            context[QuestionContextHelper.toQuestionOption(question.id)] = QuestionContextHelper.toOptionId(
                optionValue.id
            );
            context[QuestionContextHelper.toQuestionValue(question.id)] = question.optionValue.value;
        }
        if (question.type === QuestionType.MultipleChoices && question.optionValues) {
            const optionValues = question.optionValues;
            context[QuestionContextHelper.toQuestionOption(question.id)] = optionValues.map((x) =>
                QuestionContextHelper.toOptionId(x.id)
            );
            context[QuestionContextHelper.toQuestionValue(question.id)] = optionValues.values;
        }
        if (question.type === QuestionType.Comment) {
            context[QuestionContextHelper.toQuestionCommentvalue(question.id)] = question.commentValue;
        }
        if (question.type === QuestionType.Produit && question.produitValue) {
            const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            const produit = question.produitValue;
            context[questionProduitKey] = QuestionContextHelper.toProduitValue(produit);
            context[QuestionContextHelper.toQuestionProduitValue(question.id)] = produit.code;
        }
        if (question.type === QuestionType.ZipCode && question.zipCodeValue) {
            const localityDto = question.zipCodeValue;
            const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            context[questionProduitKey] = QuestionContextHelper.toZipCodeValue(localityDto);
            context = QuestionContextHelper.withRegionParisienne(localityDto, context);
        }

        const contextWithEvaluation: QuestionContext = ConfigurationUtils.evalQuestionEvalutations(question, context);
        context = contextWithEvaluation;

        return context;
    }

    //!\ ONLY FOR DEBUG
    public static getValidatedDebugContext(question: QuestionItem): QuestionContext {
        let context: QuestionContext = ObjectUtils.clone({});

        if (question.type === QuestionType.Number) {
            //context[QuestionContextHelper.toQuestionValue(question.id)] = question.numberValue;
        }
        if (question.type === QuestionType.SingleChoice && question.optionValue) {
            // const optionValue = question.optionValue;
            // context[QuestionContextHelper.toQuestionOption(question.id)] = QuestionContextHelper.toOptionId(
            //     optionValue.id
            // );
            // context[QuestionContextHelper.toQuestionValue(question.id)] = question.optionValue;
        }
        if (question.type === QuestionType.MultipleChoices && question.optionValues) {
            // const optionValues = question.optionValues;
            // context[QuestionContextHelper.toQuestionOption(question.id)] = optionValues.map((x) =>
            //     QuestionContextHelper.toOptionId(x.id)
            // );
            //context[QuestionContextHelper.toQuestionValue(question.id)] = optionValues.values;
        }
        if (question.type === QuestionType.Comment) {
            //context[QuestionContextHelper.toQuestionCommentvalue(question.id)] = question.commentValue;
        }
        if (question.type === QuestionType.Produit && question.produitValue) {
            // const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            // const produit = question.produitValue;
            // context[questionProduitKey] = QuestionContextHelper.toProduitValue(produit);
            // context[QuestionContextHelper.toQuestionProduitValue(question.id)] = produit.code;
        }
        if (question.type === QuestionType.ZipCode && question.zipCodeValue) {
            // const localityDto = question.zipCodeValue;
            // const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
            // context[questionProduitKey] = QuestionContextHelper.toZipCodeValue(localityDto);
            // context = QuestionContextHelper.withRegionParisienne(localityDto, context);
        }
        context[QuestionContextHelper.toQuestionDebug(question.id)] = question.id;

        const contextWithEvaluation: QuestionContext = ConfigurationUtils.evalQuestionEvalutations(question, context);
        context = contextWithEvaluation;

        return context;
    }

    public static updateContextWithQuestionValue(question: QuestionItem) {
        let context: QuestionContext = ObjectUtils.clone(question.context);
        if (question.showQuestion && question.isValid) {
            if (question.type === QuestionType.Number && question.numberValue) {
                const numberValue: string = question.numberValue;
                context[QuestionContextHelper.toQuestionValue(question.id)] = numberValue;
            }
            if (question.type === QuestionType.SingleChoice && question.optionValue) {
                const optionValue: OptionDto = question.optionValue;
                if (
                    !ConditionHelper.isConditionEvaluateToTrue({
                        condition: optionValue.visibility_condition,
                        rawContext: context,
                    })
                ) {
                    question.optionValue = undefined;
                    question.isValid = false;
                } else {
                    context[QuestionContextHelper.toQuestionOption(question.id)] = QuestionContextHelper.toOptionId(
                        optionValue.id
                    );
                    context[QuestionContextHelper.toQuestionValue(question.id)] = optionValue.value;
                    question.isValid = question.optionValue !== undefined;
                }
            }
            if (question.type === QuestionType.MultipleChoices && question.optionValues) {
                const optionValues: Array<OptionDto> = question.optionValues;

                let resetValues: boolean = false;
                optionValues.forEach((option: OptionDto) => {
                    if (
                        !resetValues &&
                        !ConditionHelper.isConditionEvaluateToTrue({
                            condition: option.visibility_condition,
                            rawContext: context,
                        })
                    ) {
                        resetValues = true;
                    }
                });
                if (resetValues) {
                    question.optionValues = undefined;
                    question.isValid = false;
                } else {
                    context[QuestionContextHelper.toQuestionOption(question.id)] = optionValues.map((x) =>
                        QuestionContextHelper.toOptionId(x.id)
                    );
                    context[QuestionContextHelper.toQuestionValue(question.id)] = optionValues.values;
                }
            }
            if (question.type === QuestionType.Comment && question.commentValue) {
                context[QuestionContextHelper.toQuestionCommentvalue(question.id)] = question.commentValue;
            }
            if (question.type === QuestionType.Produit && question.produitValue) {
                const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
                const produit: ProduitDto = question.produitValue;
                context[questionProduitKey] = QuestionContextHelper.toProduitValue(produit);
                context[QuestionContextHelper.toQuestionProduitValue(question.id)] = produit.code;
            }
            if (question.type === QuestionType.ZipCode && question.zipCodeValue) {
                const questionProduitKey = QuestionContextHelper.toQuestionProduitKey(question.id);
                context[questionProduitKey] = QuestionContextHelper.toZipCodeValue(question.zipCodeValue);
                context = QuestionContextHelper.withRegionParisienne(question.zipCodeValue, context);
            }

            const contextWithEvaluation: QuestionContext = ConfigurationUtils.evalQuestionEvalutations(
                question,
                context
            );
            question.context = ObjectUtils.clone(contextWithEvaluation);
        }

        return { updatedQuestion: question, updatedContext: context };
    }

    public static updateQuestionVisibilityAndContext = (params: UpdateQuestionVisibilityAndContextParams): void => {
        const { question, previousQuestion, produitPrincipalCompleteCode, log } = params;

        //* update context with question value (IF QUESTION VALID)
        QuestionsHelper.updateContextWithQuestionValue(question);

        const context: QuestionContext = ObjectUtils.clone(
            ConfigurationUtils.evalQuestionEvalutations(question, question.context)
        );

        const isVisible: boolean = QuestionsHelper.isQuestionVisible({
            question,
            context,
            produitPrincipalCompleteCode,
            log: `${log} | updateQuestionVisibilityAndContext`,
        });

        question.showQuestion = isVisible;
        question.context = context;

        //* update context with question value (IF QUESTION VALID)
        QuestionsHelper.updateContextWithQuestionValue(question);

        question.previousVisibleQuestion = previousQuestion;
    };

    public static findFirstIncompatibility(questions: Array<QuestionItem> = []): FindFirstIncompatibilityResult {
        const questionsToUse = questions.filter((question: QuestionItem) => question.showQuestion);
        let findIncompatibility: IncompatibilityDto | undefined = undefined;
        let questionWithIncompatibility: QuestionItem | undefined;
        for (const question of questionsToUse) {
            for (const incompatibility of question.incompatibility_list ?? []) {
                if (
                    !findIncompatibility &&
                    ConditionHelper.isConditionEvaluateToTrue({
                        condition: incompatibility.visibility_condition,
                        rawContext: question.context,
                    })
                ) {
                    findIncompatibility = incompatibility;
                    questionWithIncompatibility = question;
                    return { findIncompatibility, questionWithIncompatibility };
                }
            }
        }
        return { findIncompatibility: undefined, questionWithIncompatibility: undefined };
    }

    public static getNextQuestionToShow = (
        nextQuestionToShowParameters: NextQuestionToShowParameters
    ): QuestionItem | undefined => {
        const { fromQuestion, questions = [], produitPrincipalCompleteCode } = nextQuestionToShowParameters;

        const nextQuestion: QuestionItem | undefined = questions.find(
            (q: QuestionItem) => q.index > fromQuestion.index
        );

        if (nextQuestion) {
            const previousVisibleQuestion = QuestionsHelper.getPreviousVisibleQuestion(nextQuestion, questions);

            //* FIRST QUESTION : no context heritage
            if (!previousVisibleQuestion) {
                nextQuestion.context = {}; //* assign empty context if step 1, if step 2 => assign last step1 context
                QuestionsHelper.updateQuestionVisibilityAndContext({
                    question: nextQuestion,
                    produitPrincipalCompleteCode,
                    log: 'getNextQuestionToShow',
                });
            } else {
                const previousQuestion: QuestionItem = ObjectUtils.clone(previousVisibleQuestion);
                //* assign empty context + previous context
                nextQuestion.context = QuestionContextHelper.mergeContext(previousQuestion.context, {});
                QuestionsHelper.updateQuestionVisibilityAndContext({
                    question: nextQuestion,
                    previousQuestion,
                    produitPrincipalCompleteCode,
                    log: 'getNextQuestionToShow',
                });
            }

            if (nextQuestion.showQuestion && nextQuestion.type === QuestionType.Respiration) {
                QuestionsHelper.setValueOnQuestion(nextQuestion, {});
            }

            if (!nextQuestion.showQuestion || (nextQuestion.showQuestion && Boolean(nextQuestion.isValid))) {
                return QuestionsHelper.getNextQuestionToShow({
                    ...nextQuestionToShowParameters,
                    fromQuestion: nextQuestion,
                });
            }
        }
        return nextQuestion;
    };

    //* all visible question are valid and there are no nextQuestion from last visibleQuestion
    //! rename to isAllVisibleQuestionsAreValid
    public static isStepCompleted = (
        checkStepCompletedParameters: CheckStepCompletedParameters
    ): IsStepCompletedResult => {
        const { questions = [], produitPrincipal, isVendeur } = checkStepCompletedParameters;

        const { lastShownQuestion: lastVisibleQuestion } = QuestionsHelper.getLastShownQuestion(questions);

        const findNextNotVisibleQuestion = (fromQuestionIndex: number): QuestionItem | undefined => {
            const nextQuestion: QuestionItem | undefined = questions.find(
                (q: QuestionItem) => q.index > fromQuestionIndex
            );

            if (nextQuestion) {
                const previousVisibleQuestion: QuestionItem | undefined = QuestionsHelper.getPreviousVisibleQuestion(
                    nextQuestion,
                    questions
                );

                let contextToUse = QuestionContextHelper.mergeContext(previousVisibleQuestion?.context ?? {}, {});
                contextToUse = ObjectUtils.clone(
                    ConfigurationUtils.evalQuestionEvalutations(nextQuestion, contextToUse)
                );

                const nextQuestionIsVisible: boolean = QuestionsHelper.isQuestionVisible({
                    question: nextQuestion,
                    produitPrincipalCompleteCode: ProductHelper.getCompleteCode(produitPrincipal),
                    context: contextToUse,
                    log: 'findNextNotVisibleQuestion',
                });
                if (
                    !nextQuestionIsVisible ||
                    (nextQuestionIsVisible && nextQuestion.showQuestion) ||
                    (nextQuestionIsVisible &&
                        (nextQuestion.type === QuestionType.Comment || nextQuestion.type === QuestionType.Respiration))
                ) {
                    return findNextNotVisibleQuestion(nextQuestion.index);
                }
            }
            return nextQuestion;
        };

        const getNestedIncompletedQuestionsExceptedCommentAndRespiration = (
            questions: Array<QuestionItem> = []
        ): Array<QuestionItem> => {
            let incompletedQuestions: Array<QuestionItem> =
                questions.length > 0
                    ? Enumerable.from(questions)
                        .where(
                            (q) =>
                                q.type !== QuestionType.Comment &&
                                q.type !== QuestionType.Respiration &&
                                !!q.showQuestion &&
                                ConditionHelper.isConditionEvaluateToTrue({
                                    condition: q.visibility_condition,
                                    rawContext: q.context,
                                }) &&
                                !q.isValid
                        )
                        .toArray()
                    : [];
            return incompletedQuestions;
        };

        const questionToShowNotExist = (questions: Array<QuestionItem>): boolean => {
            return !Boolean(questions.find((x: QuestionItem) => x.showQuestion && x.type !== QuestionType.Respiration));
        };

        if (questions.length > 0 && !questionToShowNotExist(questions)) {
            const isStepPose = questions[0].related_step === QuestionStep.StepPose;
            const productType = ProductHelper.getProductType(produitPrincipal);
            const shouldCheckFlooringDirection =
                questions[0].context_type === QuestionContextType.Rooms &&
                isVendeur &&
                isStepPose &&
                (productType === ProductType.LamePleine || productType === ProductType.Rouleau);
            if (shouldCheckFlooringDirection && !Boolean(questions[0].context.flooring_direction)) {
                return { isEmpty: false, isStepCompleted: false };
            }
            const nextQuestion: QuestionItem | undefined = lastVisibleQuestion
                ? findNextNotVisibleQuestion(lastVisibleQuestion.index)
                : findNextNotVisibleQuestion(-1);
            if (nextQuestion) {
                return { isEmpty: false, isStepCompleted: false };
            } else {
                const incompletedQuestions = getNestedIncompletedQuestionsExceptedCommentAndRespiration(questions);
                return { isEmpty: false, isStepCompleted: incompletedQuestions.length === 0 };
            }
        }

        return { isStepCompleted: true, isEmpty: true };
    };
}
